import React, { useState } from 'react'
import preProcess from '../../preprocess'
import { Form, Button, Input, message, AutoComplete } from 'antd'
import { createAction, ActionNames } from 'app-redux/actions'
import './styles.scss'

const { create, Item } = Form
const spinningSelector = '.form'
const Main = props => {
    const { form, data, translate, hideModal, updateRecord, createRecord, getTableData } = props
    const { getFieldDecorator, validateFieldsAndScroll } = form
    const validations = {
        key: {
            rules: [{
                required: true,
                message: translate('products.key.required')
            }],
            initialValue: data && data.key
        },
        make: {
            rules: [{
                required: true,
                message: translate('products.make.required')
            }],
            initialValue: data && data.make
        },
        model: {
            rules: [{
                required: true,
                message: translate('products.model.required')
            }],
            initialValue: data && data.model
        },
        colour: {
            // rules: [{
            //     required: true,
            //     message: translate('products.colour.required')
            // }],
            initialValue: data && data.colour
        },
        description: {
            // rules: [{
            //     required: true,
            //     message: translate('products.description.required')
            // }],
            initialValue: data && data.description
        },
    }
    const onSubmit = event => {
        event.preventDefault()
        validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return
            }
            window.startSpinning(spinningSelector)
            try {
                if (data) {
                    delete values.key
                    const { error, payload } = await updateRecord(data.key, values)
                    if (error) {
                        throw payload.response
                    }
                } else {
                    values.key = values.key.toUpperCase()
                    const { error, payload } = await createRecord(values)
                    if (error) {
                        throw payload.response
                    }
                }
                if (data) {
                    message.success(translate('products.update.success'))
                } else {
                    message.success(translate('products.create.success'))
                }
                if (hideModal instanceof Function) {
                    hideModal()
                }
                if (getTableData instanceof Function) {
                    getTableData()
                }
            } catch (e) {
                console.log(e)
                if (data) {
                    message.error(translate('products.update.fail'))
                } else {
                    message.error(translate('products.create.fail'))
                }
            }
            window.stopSpinning(spinningSelector)
        })
    }
    const { key, make, model, colour, description } = validations
    return (
        <div className='form'>
            <Form onSubmit={onSubmit}>
                <div className="inline">
                    <Item label={translate('products.key.label')} hasFeedback>
                        {
                            getFieldDecorator('key', key)(
                                <Input disabled={!!data} className='input uppercase'
                                    placeholder={translate('products.key.placeholder')} />
                            )
                        }
                    </Item>
                    <Item label={translate('products.make.label')} hasFeedback>
                        {
                            getFieldDecorator('make', make)(
                                <Input className='input' placeholder={translate('products.make.placeholder')} />
                            )
                        }
                    </Item>
                </div>
                <div className="inline">
                    <Item label={translate('products.model.label')} hasFeedback>
                        {
                            getFieldDecorator('model', model)(
                                <Input className='input' placeholder={translate('products.model.placeholder')} />
                            )
                        }
                    </Item>
                    <Item label={translate('products.colour.label')} hasFeedback>
                        {
                            getFieldDecorator('colour', colour)(
                                <Input className='input' placeholder={translate('products.colour.placeholder')} />
                            )
                        }
                    </Item>
                </div>
                <Item label={translate('products.description.label')} hasFeedback>
                    {
                        getFieldDecorator('description', description)(
                            <Input className='input' placeholder={translate('products.description.placeholder')} />
                        )
                    }
                </Item>
                <div className="legends">
                    <ul>
                        <li>
                            <span className="legend">Key</span>
                            <span className="text">Unique Key Identifying the product</span>
                        </li>
                        <li>
                            <span className="legend">Model</span>
                            <span className="text">Model of the product e.g Polo 1.2L TDI</span>
                        </li>
                        <li>
                            <span className="legend">Make</span>
                            <span className="text">Brand or Make of the product e.g Volkswagen</span>
                        </li>
                    </ul>
                </div>
                <div className="actions">
                    <Button type="primary" className="btn primary-btn"
                        htmlType='submit'>{translate('parts.submit')}</Button>
                    <Button className="btn red-btn-text" onClick={hideModal}>{translate('parts.cancel')}</Button>
                </div>

            </Form>
        </div>
    )
}
const mapStateToProps = ({ }) => {
    return {}
}
const bindAction = dispatch => {
    return {
        createRecord: data => {
            return dispatch(createAction(ActionNames.CREATE_ENTITY, {
                entityName: 'products',
                ...data
            }))
        },
        updateRecord: (entityId, data) => {
            return dispatch(createAction(ActionNames.UPDATE_ENTITY, {
                entityId,
                entityName: 'products',
                ...data
            }))
        }
    }
}
Main.displayName = 'Part-Form'
export default preProcess(create()(Main), {
    localize: true,
    connect: [mapStateToProps, bindAction]
})