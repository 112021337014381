import React, { useState } from 'react'
import preProcess from '../../preprocess'
import { Form, Button, Input, message, AutoComplete } from 'antd'
import { createAction, ActionNames } from 'app-redux/actions'
import './styles.scss'

const { create, Item } = Form
const { TextArea } = Input
const spinningSelector = '.form'
const Main = props => {
    const { form, data, translate, hideModal, updateRecord, createRecord, getTableData } = props
    const { getFieldDecorator, validateFieldsAndScroll } = form
    const validations = {
        name: {
            rules: [{
                required: true,
                message: translate('booking.name.required')
            }],
            initialValue: data && data.name
        },
        email: {
            rules: [{
                required: true,
                message: translate('booking.email.required')
            }],
            initialValue: data && data.email
        },
        phoneNumber: {
            rules: [{
                required: true,
                message: translate('booking.phone.required')
            }],
            initialValue: data && data.phoneNumber
        },
        address: {
            rules: [{
                required: true,
                message: translate('booking.address.required')
            }],
            initialValue: data && data.address
        },
        description: {
            rules: [{
                required: true,
                message: translate('booking.description.required')
            }],
            initialValue: data && data.description
        }
    }

    const onSubmit = event => {
        event.preventDefault()
        validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return;
            }
            window.startSpinning(spinningSelector);
            try {
                if (data) {
                    const { error, payload } = await updateRecord(data.key, values);
                    if (error) {
                        throw payload.response;
                    }
                } else {
                    const { error, payload } = await createRecord(values);
                    if (error) {
                        throw payload.response;
                    }
                }
                if (data) {
                    message.success("Your request has been updated successfully");
                } else {
                    message.success("Your request has been submitted successfully");
                }
                if (hideModal instanceof Function) {
                    hideModal();
                }
                if (getTableData instanceof Function) {
                    getTableData();
                }
            } catch (e) {
                console.log(e);
                if (data) {
                    message.error("There was an error while updating your request, please contact us");
                } else {
                    message.error("There was an error while submitting your request, please contact us");
                }
            }
            window.stopSpinning(spinningSelector);
        });
    }
    const { name, email, description, address, phoneNumber } = validations

    return (
        <div className='form booking-form'>
            <p className="heading">Customer Details</p>
            <Form onSubmit={onSubmit}>
                <div className="inline">
                    <Item label={translate('booking.name.label')} hasFeedback>
                        {
                            getFieldDecorator('name', name)(
                                <Input className='input' placeholder={translate('booking.name.placeholder')} />
                            )
                        }
                    </Item>
                    <Item label={translate('booking.email.label')} hasFeedback>
                        {
                            getFieldDecorator('email', email)(
                                <Input className='input' placeholder={translate('booking.email.placeholder')} />
                            )
                        }
                    </Item>
                    <Item label={translate('booking.phone.label')} hasFeedback>
                        {
                            getFieldDecorator('phoneNumber', phoneNumber)(
                                <Input className='input' placeholder={translate('booking.phone.placeholder')} />
                            )
                        }
                    </Item>
                </div>
                <Item label={translate('booking.address.label')} hasFeedback>
                    {
                        getFieldDecorator('address', address)(
                            <TextArea className='input' placeholder={translate('booking.address.placeholder')}></TextArea>
                        )
                    }
                </Item>
                <Item label={translate('booking.description.label')} hasFeedback>
                    {
                        getFieldDecorator('description', description)(
                            <TextArea className='input' autoSize={{ minRows: 4, maxRows: 4 }} placeholder={translate('booking.description.placeholder')}></TextArea>
                        )
                    }
                </Item>
                <div className="actions">
                    <Button type="primary" className="btn primary-btn"
                        htmlType='submit'>{translate('parts.submit')}</Button>
                    <Button className="btn red-btn-text"
                        htmlType='button' onClick={hideModal}>{translate('parts.cancel')}</Button>
                </div>

            </Form>
        </div>
    )
}
const mapStateToProps = ({ }) => {
    return {}
}
const bindAction = dispatch => {
    return {
        createRecord: data => {
            return dispatch(createAction(ActionNames.CREATE_ENTITY, {
                entityName: 'service/guestJob',
                ...data
            }))
        },
        updateRecord: (entityId, data) => {
            return dispatch(createAction(ActionNames.UPDATE_ENTITY, {
                entityId,
                entityName: 'service/guestJob',
                ...data
            }))
        }
    }
}
Main.displayName = 'Part-Form'
export default preProcess(create()(Main), {
    localize: true,
    connect: [mapStateToProps, bindAction]
})