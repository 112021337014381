import React from 'react'
import './styles.scss'
import { Button, Form, Input } from 'antd'

const { Item } = Form
var view = function () {
    const { record, form } = this.props
    const { email, customerKey } = record || {}
    const { getFieldDecorator } = form
    return (
        <div className="send" onSubmit={this.send}>
            <Form>
                <div className="form">
                    <Item hasFeedback={true} label="Email">
                        {getFieldDecorator('email', {
                            rules: [{ required: true, message: 'Email is Required' }],
                            initialValue: email,
                        })(<Input className="input" />)}
                    </Item>
                    <Item hasFeedback={true} label="Phone Number">
                        {getFieldDecorator('phoneNo', {
                            initialValue: customerKey,
                        })(<Input className="input" />)}
                    </Item>
                    <div className="actions">
                        <Button htmlType="submit" className="btn primary-btn">
                            Send
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    )
}
export default view
