import React, { useState, useEffect } from "react";
import { Select, Spin } from "antd";
import preProcess from "../preprocess";
import { createAction, ActionNames } from "app-redux/actions";
const { Option } = Select;
const App = (props) => {
  const {
    keyName,
    disabled,
    placeholder,
    getEntity,
    getEntities,
    searchField,
    formatter,
    filterData
  } = props;
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getData(props.value);
  }, []);
  const getData = async (value) => {
    setLoading(true);
    try {
      if (value && value.length) {
        const entityId = value.map((item) => {
          if (keyName) {
            return item[keyName];
          } else {
            return item;
          }
        });
        const { error, payload } = await getEntity(entityId.join(","));
        if (error) {
          throw payload.response;
        }
        let { data } = payload;
        if (!Array.isArray(data)) {
          data = [data];
        }
        setOptions(
          data.map((item) => {
            if (formatter instanceof Function) {
              const { label, value } = formatter(item);
              return {
                label,
                value:
                  typeof value !== "string" ? JSON.stringify(value) : value,
              };
            }
            return item;
          })
        );
        setValue(
          data.map((item) => {
            if (formatter instanceof Function) {
              const { value } = formatter(item);
              return typeof value !== "string" ? JSON.stringify(value) : value;
            }
            return item;
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  const onChange = (value) => {
    setValue(value);
    if (props.onChange instanceof Function) {
      props.onChange(
        value.map((item) => {
          try {
            const obj = JSON.parse(item);
            if (typeof obj === "object") {
              return obj;
            } else {
              return item;
            }
          } catch (e) {
            return item;
          }
        })
      );
    }
  };

  const onSearch = async (search) => {
    if (!search) {
      return;
    }
    setLoading(true);
    try {
      const { error, payload } = await getEntities({
        searchField,
        search,
      });
      if (error) {
        throw payload.response;
      }
      let { data } = payload.data;
      if (data.length) {
        if (filterData instanceof Function) {
          data = data.filter(filterData);
        }
        setOptions(
          data.map((item) => {
            if (formatter instanceof Function) {
              const { label, value } = formatter(item);
              item = {
                label,
                value:
                  typeof value !== "string" ? JSON.stringify(value) : value,
              };
            }
            return item;
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  return (
    <Select
      className="select"
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onSearch={onSearch}
      showSearch={true}
      showArrow={false}
      mode="multiple"
      filterOption={false}
      notFoundContent={loading && <Spin size="small" />}
    >
      {options.map((item, index) => {
        const { label, value } = item;
        return (
          <Option key={index} value={value}>
            {label}
          </Option>
        );
      })}
    </Select>
  );
};
const bindAction = (dispatch, ownProps) => {
  const { entityName } = ownProps;
  return {
    getEntity: (entityId) => {
      return dispatch(
        createAction(ActionNames.GET_ENTITY, {
          entityName,
          entityId,
        })
      );
    },
    getEntities: (data) => {
      return dispatch(
        createAction(ActionNames.GET_ENTITIES, {
          entityName,
          ...data,
        })
      );
    },
  };
};
export default preProcess(App, {
  localize: true,
  connect: [null, bindAction],
});
