import React, { useEffect } from "react";
import preProcess from "../preprocess";
import { Form, message, Select, Button, Input, Tooltip } from "antd";
import { createAction, ActionNames } from "app-redux/actions";

const { create, Item } = Form;
const { Option } = Select;
const spinningSelector = ".backup-settings-form";
const Main = (props) => {
  const { translate, hideModal, form, saveConfig, getConfig } = props;
  const { getFieldDecorator, validateFieldsAndScroll, setFieldsValue } = form;
  const onLoad = async () => {
    window.startSpinning(spinningSelector);
    try {
      const { error, payload } = await getConfig();
      if (error) {
        throw payload.response;
      }
      const { value: cronString } = payload.data;
      setFieldsValue({
        cronString,
      });
    } catch (e) {
      console.error({ e });
      setFieldsValue({
        cronString: "",
      });
    }
    window.stopSpinning(spinningSelector);
  };
  useEffect(() => {
    onLoad();
  }, []);
  const onSubmit = (event) => {
    event.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      window.startSpinning(spinningSelector);
      try {
        const { error, payload } = await saveConfig(values.cronString);
        if (error) {
          throw payload.response;
        }
        if (hideModal instanceof Function) {
          hideModal();
        }
        message.success(translate("backup.settings.success"));
      } catch (e) {
        console.log(e);
        if (e && e.status === 409) {
          message.error(translate("backup.settings.demo.fail"));
        } else {
          message.error(translate("backup.settings.fail"));
        }
      }
      window.stopSpinning(spinningSelector);
    });
  };
  return (
    <Form onSubmit={!window.app.isDemoEnabled ? onSubmit : null}>
      <div className="backup-settings-form new-form">
        <div className="form">
          <Item hasFeedback label={translate("backup.cronString.label")}>
            {getFieldDecorator("cronString")(
              <Input
                className="input"
                placeholder={translate("backup.cronString.label")}
              />
            )}
          </Item>
          <p>
            <a href="https://crontab.guru/examples.html" target="_blank">
              Check Cron String Examples and Reference
            </a>
          </p>
        </div>
        <div className="actions">
          <Tooltip
            title={window.app.isDemoEnabled ? window.app.demoDisabledText : ""}
          >
            <Button
              disabled={window.app.isDemoEnabled}
              htmlType="submit"
              className="btn primary-btn"
            >
              {translate("backup.save")}
            </Button>
          </Tooltip>
          <Button onClick={hideModal} className="btn red-btn-text">
            {translate("backup.cancel")}
          </Button>
        </div>
      </div>
    </Form>
  );
};
const mapStateToProps = ({ }) => {
  return {};
};
const bindAction = (dispatch) => {
  return {
    saveConfig: (cronString) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "backup",
          entityId: "schedule",
          cronString,
        })
      );
    },
    getConfig: () => {
      return dispatch(
        createAction(ActionNames.GET_ENTITY, {
          entityName: "configuration",
          entityId: "BACKUP_CRON_SCHEDULE",
        })
      );
    },
  };
};
export default preProcess(create()(Main), {
  localize: true,
  connect: [mapStateToProps, bindAction],
});
