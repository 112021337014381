import React, { useState } from "react";
import preProcess from "../preprocess";
import "./styles.scss";
import { createAction, ActionNames } from "app-redux/actions";
import { Button, message, Tooltip, Icon, Modal } from "antd";

const spinningSelector = "body";
const App = (props) => {
  const {
    translate,
    record,
    getData,
    getTableData,
    hideModal,
    createRecord,
    restore,
  } = props;
  const { key, url } = record;
  let disabled = false;
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    if (loading) {
      message.info("Restore already in progress");
      return;
    }
    const modal = Modal.confirm({
      title: "Please confirm the restore.",
      content: "Warning : All data in the system will be overridden.",
      okType: "danger",
      onOk: async () => {
        window.startSpinning(spinningSelector);
        setLoading(true);
        modal.destroy();
        try {
          message.success(translate("backup.restore.success"));
          const { error, payload } = await restore(key);
          if (error) {
            throw payload.response;
          }
          if (getData instanceof Function) {
            getData();
          }
          if (getTableData instanceof Function) {
            getTableData();
          }
          if (hideModal instanceof Function) {
            hideModal();
          }
          message.success(translate("backup.restore.finished"));
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } catch (e) {
          console.log(e);
          message.error(translate("backup.restore.fail"));
          window.stopSpinning(spinningSelector);
        }
        setLoading(false);
      },
    });
  };
  let btnMsg = "";
  if (!url) {
    btnMsg = translate("backup.progress");
    disabled = true;
  } else {
    btnMsg = translate("backup.restore");
  }
  return (
    <React.Fragment>
      {!disabled && (
        <Tooltip
          placement="top"
          title={
            window.app.isDemoEnabled ? window.app.demoDisabledText : "Download"
          }
        >
          <a
            href={window.app.isDemoEnabled || disabled ? "" : url}
            target="_blank"
          >
            <Button
              className={"primary-btn-text backup-btn "}
              disabled={window.app.isDemoEnabled || disabled}
            >
              <Icon type="download" />
            </Button>
          </a>
        </Tooltip>
      )}
      <Tooltip
        placement="top"
        title={window.app.isDemoEnabled ? window.app.demoDisabledText : btnMsg}
      >
        <Button
          className={"blue-btn-text backup-btn " + (disabled ? "disabled" : "")}
          disabled={window.app.isDemoEnabled || disabled}
          onClick={onClick}
        >
          <Icon spin={loading} type="sync" />
        </Button>
      </Tooltip>
    </React.Fragment>
  );
};
const bindAction = (dispatch) => {
  return {
    restore: (entityId) => {
      return dispatch(
        createAction(ActionNames.GET_ENTITY, {
          entityName: "backup/restore",
          entityId,
        })
      );
    },
  };
};
export default preProcess(App, {
  localize: true,
  connect: [null, bindAction],
});
