import React from 'react'
import './styles.scss'
import SupportForm from './supportForm'
import Wave from '../../components/wave';
/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
    const { translate } = this.props;
    return (
        <div className="support-form">
            <SupportForm />
            <Wave />
        </div>
    )
}
export default view
