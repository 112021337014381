import React, { useState } from "react";
import preProcess from "../preprocess";
import "./styles.scss";
import { createAction, ActionNames } from "app-redux/actions";
import { Button, message, Tooltip, Icon } from "antd";

const App = (props) => {
  const { translate, getData, getTableData, hideModal, createRecord } = props;
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    setLoading(true);
    try {
      const { error, payload } = await createRecord();
      if (error) {
        throw payload.response;
      }
      if (getData instanceof Function) {
        getData();
      }
      if (getTableData instanceof Function) {
        getTableData();
      }
      if (hideModal instanceof Function) {
        hideModal();
      }
      message.success(translate("backup.create.success"));
    } catch (e) {
      console.log(e);
      message.error(translate("backup.create.fail"));
    }
    setLoading(false);
  };
  const btnMsg = translate("backup.create");
  return (
    <Tooltip
      title={
        window.app.isDemoEnabled
          ? window.app.demoDisabledText
          : "Create System Backup"
      }
    >
      <Button
        className="primary-btn"
        disabled={window.app.isDemoEnabled}
        onClick={!window.app.isDemoEnabled ? onClick : null}
      >
        <span className="icon">
          <Icon type="cloud-upload" />
        </span>
        <span className="text">Create Backup</span>
      </Button>
    </Tooltip>
  );
};
const bindAction = (dispatch) => {
  return {
    createRecord: () => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "backup",
        })
      );
    },
  };
};
export default preProcess(App, {
  localize: true,
  connect: [null, bindAction],
});
