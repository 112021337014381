import React from 'react'
import './styles.scss'
import { Row, Col } from 'antd';
import LoginForm from './loginForm';
import Wave from '../../components/wave';

/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
    return (
        <div>
            <LoginForm />
            <Wave />
        </div>
    )
}
export default view
