import Invoice from "./invoice";
export default {
  entityName: "My Invoices",
  ...Invoice,
  addAction: false,
  extraSecurityCheck: (allowedPermissions, allowedRoles, permissions, role) => {
    return role !== "admin";
  },
  headerActions : []
};
