export default {
  entity: "customer",
  entityName: "Customers",
  formId: "CustomerForm",
  defaultSort: {
    sort: "createdAt",
    sortType: "desc",
  },
  pagination: {
    type: "server",
  },
  search: {
    filters: [
      {
        title: "Name",
        type: "input",
        key: "customerName",
      },
      {
        title: "Email",
        type: "input",
        key: "email",
      },
      {
        title: "Mobile Number",
        type: "input",
        key: "key",
      }
    ],
    searchOnSubmit: true,
  },
  columns: [
    {
      title: "Customer Name",
      dataIndex: "customerName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "key",
    },
  ],
  localization : {
    "delete.content" : "All the jobs and invoices for this customer will also be deleted."
  }
};
