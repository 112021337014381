import React, { Component } from "react";
import ComponentView from "./view";
import preProcess from "../../preprocess";
import { Form, message } from "antd";
import { getObjectsDiff } from "../../../utils/common";
import { createAction, ActionNames } from "../../../redux/actions";
import moment from "moment";
const spinningSelector = ".new-form";

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      partOptions: [],
      serviceOptions: [],
      customerProducts: [],
      users: [],
      customers: [],
      customer: null,
      fetchingCustomers: false,
    };
    this.onSearch = this.onSearch.bind(this);
    this.getCustomerDetails = this.getCustomerDetails.bind(this);
  }

  /**
   * get Customer Parts
   */
  async getCustomerDetails(customerValue) {
    const { getSingleCustomer, getProducts } = this.props;
    try {
      if (customerValue) {
        if (customerValue !== "create-new") {
          const { error, payload } = await getSingleCustomer(customerValue);
          if (error) {
            throw payload.response;
          }
          let { products } = payload.data;
          products = products || [];
          if (!Array.isArray(products)) {
            products = [products];
          }
          this.setState({
            customerProducts: products.map(({ key, make, model, colour }) => {
              return { key, make, model, colour };
            }),
            customer: payload.data,
            customers : [payload.data]
          });
        } else {
          this.setState({
            customerProducts: [],
            customer: null,
            customers : []
          });
        }
      }
    } catch (e) {
      console.error("Error in fetching Customer Products", { e });
    }
  }

  async onSearch(search) {
    const { getCustomers } = this.props;
    try {
      if (search) {
        this.setState({
          fetchingCustomers: true,
        });
        const { error, payload } = await getCustomers(search);
        if (error) {
          throw payload.response;
        }
        this.setState({
          customers: payload.data.data,
          fetchingCustomers: false,
        });
      }
    } catch (e) {
      console.error("Error in searching customers", { e });
      this.setState({
        fetchingCustomers: false,
      });
    }
  }

  /**
   * Sets Validations for fields
   */
  setValidations() {
    const { data } = this.props;
    const { jobNumber } = this.state;
    this.validations = {
      key: {
        rules: [
          {
            required: true,
            message: "Enter Job Number",
          },
        ],
        initialValue: data ? data.key : jobNumber,
      },
      corporateNumber: {
        initialValue: data ? data.corporateNumber : null,
      },
      customer: {
        rules: [
          {
            required: true,
            message: "Select Customer",
          },
        ],
        initialValue:
          data && data.customer && typeof data.customer === "string"
            ? data.customer
            : "create-new",
      },
      date: {
        rules: [
          {
            required: true,
            message: "Enter Date",
          },
        ],
        initialValue: data ? moment(new Date(data.date)) : moment(new Date()),
      },
      productType: {
        rules: [
          {
            required: true,
            message: "Enter Product Type",
          },
        ],
        initialValue: data ? data.productType : null,
      },
      productBrand: {
        rules: [
          {
            required: true,
            message: "Enter Product Brand",
          },
        ],
        initialValue: data ? data.productBrand : null,
      },
      model: {
        rules: [
          {
            required: true,
            message: "Enter Model",
          },
        ],
        initialValue: data ? data.model : null,
      },
      jobStatus: {
        rules: [
          {
            required: true,
            message: "Select Job Status",
          },
        ],
        ...(data
          ? {
              initialValue: data.jobStatus,
            }
          : { initialValue: "Pending" }),
      },
      sign: {
        initialValue: data ? data.sign : null,
      },
      faultReported: {
        initialValue: data ? data.faultReported : null,
      },
      faultDiagnosed: {
        initialValue: data ? data.faultDiagnosed : null,
      },
      serviceDetails: {
        initialValue: data ? data.serviceDetails : null,
      },
      repairCompletedDate: {
        ...(data && data.repairCompletedDate
          ? {
              initialValue: moment(new Date(data.repairCompletedDate)),
            }
          : {}),
      },
      partsUsed: {
        initialValue: data ? data.partsUsed : null,
      },
      partsCosts: {
        initialValue: data ? data.partsCosts : null,
      },
      labourTransportCosts: {
        initialValue: data ? data.labourTransportCosts : null,
      },
      generalRemarks: {
        initialValue: data ? data.generalRemarks : null,
      },
      attachment: {
        initialValue: data ? data.attachment : null,
      },
      /**
       * Remember if initialValue is not defined, setFieldsValue and resetFields also doesn't work somehow
       * also don't use data && data.parts as it still doesn't work that way
       */
      parts: {
        initialValue: data ? data.parts : [],
      },
      services: {
        initialValue: data ? data.services : [],
      },
      product: {
        rules: [
          {
            required: true,
            message: "Product is Required",
          },
        ],
        ...(data &&
        data.productBrand &&
        data.model &&
        data.serialNumber
          ? {
              initialValue: JSON.stringify({
                key: data.serialNumber,
                make: data.productBrand,
                model: data.model,
                colour: data.productType,
              }),
            }
          : {}),
      },
      assignedTo: {
        rules: [
          {
            required: true,
            message: "Assignee is Required",
          },
        ],
        initialValue: data && data.assignedTo,
      },
      discount: {
        initialValue: data ? data.discount : undefined,
      },
    };
  }

  /**
   * ComponentDidMount Hook
   */
  async componentDidMount() {
    const { getJobNumber, data, getUsers } = this.props;
    try {
      if (!data) {
        const { error, payload } = await getJobNumber();
        if (error) {
          throw payload.response;
        }
        this.setState({
          jobNumber: payload.data,
        });
      }
      if (data && data.customer && typeof data.customer === "string") {
        this.getCustomerDetails(data.customer);
      }
      if (data && data.assignedTo && data.assignedTo.length) {
        const { error, payload } = await getUsers(data.assignedTo.join(","));
        if (error) {
          throw payload.response;
        }
        if (Array.isArray(payload.data)) {
          this.setState({
            users: payload.data,
          });
        } else {
          this.setState({
            users: [payload.data],
          });
        }
      }
    } catch (e) {
      console.error("Error while mounting service job", { e });
    }
  }

  getCustomerFormData() {
    return new Promise((resolve) => {
      try {
        this.customerFormRef.props.form.validateFieldsAndScroll(
          (errors, values) => {
            resolve({
              errors,
              values,
            });
          }
        );
      } catch (e) {
        resolve(e);
      }
    });
  }

  //   isDateChanged(date1, date2) {
  //     if (date1 && date2) {
  //       return new Date(date1).getTime() !== new Date(date2).getTime();
  //     } else {
  //       return true;
  //     }
  //   }

  handleSubmit(event) {
    event.preventDefault();
    const {
      role,
      getTableData,
      hideModal,
      createRecord,
      updateRecord,
      uploadImage,
      data,
      form,
      uid,
      permissions,
    } = this.props;
    const { validateFieldsAndScroll } = form;
    validateFieldsAndScroll(async (errors, values) => {
      const form = await this.getCustomerFormData("customer");
      errors = form.errors
        ? {
            ...form.errors,
          }
        : errors;
      if (errors) {
        return;
      }
      // if ((permissions || []).indexOf("SERVICE_EDIT") === -1 || role.toLowerCase() === 'admin') {
      //   values.assignedTo = [uid];
      // }
      if (data && data.jobStatus === "Completed") {
        if (role !== "admin") {
          message.error("This job is already completed");
          return;
        }
        // const { parts, labourTransportCosts, sign, ...remValues } = values;
        // const { date, repairCompletedDate, ...remObj } = getObjectsDiff(remValues, data);
        // const msg = 'Admin can only change parts and labour costs after job is complete';
        // if (date && this.isDateChanged(date, data.date)) {
        //     message.error(msg);
        //     return;
        // }
        // if (repairCompletedDate && this.isDateChanged(repairCompletedDate, data.repairCompletedDate)) {
        //     message.error(msg);
        // }
        // if (Object.keys(remObj).length > 0) {
        //     message.error('Admin can only change parts,signature and labour costs after job is complete');
        //     return;
        // }
      }
      if (values.customer === "create-new") {
        values.customer = form.values;
      } /*else if (customers.findIndex(customer => customer.key === values.customer) === -1) {
                message.error('Select Customer First');
                return;
            }*/
      if (typeof values.product === "string") {
        values.product = JSON.parse(values.product);
      }
      if (typeof values.parts === "string") {
        values.parts = JSON.parse(values.parts);
        if (values.parts.length) {
          values.parts.map((item) => {
            if (typeof item === "string") {
              return JSON.parse(item);
            }
            return item;
          });
        }
      }
      const { key, make, colour, model } = values.product;
      values.productBrand = make;
      values.productType = colour;
      values.model = model;
      values.serialNumber = key;
      values.saveCustomer = true;
      values.discount = values.discount || 0;
      delete values.product;
      window.startSpinning(spinningSelector);
      try {
        const { attachment } = values;
        if (attachment) {
          values.attachment = [];
          await Promise.all(
            attachment.map(async (file) => {
              if (typeof file === "object") {
                const { dataUri, name, url, type } = file;
                if (dataUri) {
                  const { error, payload } = await uploadImage({
                    name,
                    file: dataUri,
                    type,
                  });
                  if (error) {
                    throw payload.response;
                  }
                  values.attachment.push({
                    name,
                    url: payload.data.url,
                    type,
                  });
                } else {
                  values.attachment.push({
                    name,
                    url,
                    type,
                  });
                }
              }
            })
          );
        } else {
          values.attachment = [];
        }
        if (data) {
          const { error, payload } = await updateRecord({
            entityId: data.key,
            ...getObjectsDiff(values, data),
            customer : values.customer,
            assignedTo: values.assignedTo,
            parts: values.parts || [],
            attachment: values.attachment,
          });
          if (error) {
            throw payload.response;
          }
        } else {
          const { error, payload } = await createRecord(values);
          if (error) {
            throw payload.response;
          }
        }
        if (hideModal instanceof Function) {
          hideModal();
        }
        if (getTableData instanceof Function) {
          getTableData();
        }
        if (data) {
          message.success("Service Job Updated Successfully");
        } else {
          message.success("Service Job Created Successfully");
        }
      } catch (e) {
        console.log(e);
        if (data) {
          message.error("Error while Updating Service");
        } else {
          if (e.status === 404) {
            message.error("Service Job with this Job Number already Exists.");
          } else {
            message.error("Error while Creating Service");
          }
        }
      }
      window.stopSpinning(spinningSelector);
    });
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    getUsers: (entityId) => {
      return dispatch(
        createAction(ActionNames.GET_ENTITY, {
          entityName: "users",
          entityId,
        })
      );
    },
    getProducts: (entityId) => {
      return dispatch(
        createAction(ActionNames.GET_ENTITY, {
          entityName: "products",
          entityId,
        })
      );
    },
    getSingleCustomer: (entityId) => {
      return dispatch(
        createAction(ActionNames.GET_ENTITY, {
          entityId,
          entityName: "customer",
        })
      );
    },
    searchEntity: (data) => {
      return dispatch(createAction(ActionNames.GET_ENTITIES, data));
    },
    createRecord: (data) => {
      const action = createAction(ActionNames.CREATE_ENTITY, {
        entityName: "service",
        ...data,
      });
      action.type = action.type + "_service";
      return dispatch(action);
    },
    updateRecord: ({ entityId, ...data }) => {
      const action = createAction(ActionNames.UPDATE_ENTITY, {
        entityName: "service",
        entityId,
        ...data,
      });
      action.type = action.type + "_service";
      return dispatch(action);
    },
    uploadImage: (file) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "storage",
          ...file,
          fileCode: "serviceAttachment",
        })
      );
    },
    getJobNumber: () => {
      return dispatch(
        createAction(ActionNames.GET_ENTITY, {
          entityName: "service",
          entityId: "getJobNumber",
        })
      );
    },
    getCustomers: (search) => {
      const action = createAction(ActionNames.GET_ENTITIES, {
        entityName: "customer",
        searchField: "customerName",
        from: -1,
        search,
      });
      action.type = action.type + "_customer";
      return dispatch(action);
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user, customer, config }) => {
  const { configuration } = config || {};
  const { CURRENCY } = configuration || {};
  const { data } = customer || {};
  const { role, permissions, uid } = user || {};
  return {
    customers: data,
    role,
    CURRENCY,
    permissions,
    uid,
  };
};
Main.displayName = "Service-Form";
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
