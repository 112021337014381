import React from 'react'
import './styles.scss'
import { Input, Button, Form, Radio } from 'antd'
import Link from '../../components/link'
import Wave from '../../components/wave';

const FormItem = Form.Item
/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
    const { translate, form, ui } = this.props
    const { getFieldDecorator } = form
    const { email } = this.validations
    return (
        <div className="page">
            <div className="general-form forgetPassword">
                <div className="content">
                    <div className="form">
                        <div className="fields">
                            <p className="title">{translate('forgot.title')}</p>
                            <p className="description">{translate('forgot.subTitle')}</p>
                            <Form onSubmit={this.handleSubmit.bind(this)}>
                                <FormItem hasFeedback={true} label="Email">
                                    {
                                        getFieldDecorator('email', email)(<Input
                                            placeholder={translate('common.email.placeholder')}
                                            className="input" />)
                                    }
                                </FormItem>
                                <div className="action">
                                    <Button htmlType={'submit'} className="btn primary-btn">{translate('forgot.submit')}</Button>
                                </div>
                            </Form>
                        </div>
                        <Link className="registerLink" routeKey={'login'}>Clicked by mistake? Login</Link>
                    </div>
                </div>
            </div>
            <Wave />
        </div>
    )
}
export default view
