export default {
  entity: "faq",
  entityName: "FAQ",
  formId: "FAQForm",
  pagination: {
    type: "server",
  },
  defaultSort: {
    sort: "createdAt",
    sortType: "desc",
  },
  search: {
    searchOnSubmit: true,
    filters: [
      {
        title: "Question",
        type: "input",
        key: "question",
      },
      {
        title: "Answer",
        type: "input",
        key: "answer",
      },
    ],
  },
  columns: ({ sharedState: { translate }, getTableData }) => [
    {
      dataIndex: "question",
      title: "Question",
    },
    {
      dataIndex: "answer",
      title: "Answer",
    },
  ],
};
