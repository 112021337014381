import React from 'react'
import './styles.scss'
import { Form, Input, Button, Select, Checkbox, Icon, Tooltip } from 'antd'
import FilePicker from '../../../components/file-picker'
import CKEditor from '../../../components/CKEditorComponent'
import Link from 'core-components/link'

const { Item } = Form
const { Option } = Select
const { TextArea } = Input
const types = [
    'file',
    'text',
    'html',
    'boolean'
]
const imageExtensions = [
    'png',
    'jpg',
    'jpeg'
]
var view = function () {
    const { form, hideModal, data, translate } = this.props
    const { key, type, value, description, file } = this.validations
    const { getFieldDecorator, getFieldValue } = form
    const typeValue = getFieldValue('type') || data && data.type || 'text'
    return (
        <Form onSubmit={this.handleSubmit.bind(this)}>
            <div className="new-form configuration-form">
                <div className="form">
                    <Item hasFeedback={true} label={translate('configuration.key.label')}>
                        {
                            getFieldDecorator('key', key)(
                                <Input maxLength={100}
                                    disabled={!!data}
                                    className='input uppercase'
                                    placeholder={translate('configuration.key.placeholder')}
                                />
                            )
                        }
                    </Item>
                    <Item hasFeedback={true} label={translate('configuration.type.label')}>
                        {
                            getFieldDecorator('type', type)(
                                <Select
                                    className='select'
                                    disabled={!!data}
                                    placeholder={translate('configuration.type.placeholder')}
                                >
                                    {
                                        types.map((type, index) => {
                                            return (
                                                <Option
                                                    key={index}
                                                    value={type}
                                                >
                                                    {type}
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            )
                        }
                    </Item>
                    {typeValue === 'text' && <Item hasFeedback={true} label={translate('configuration.value.label')}>
                        {
                            getFieldDecorator('value', value)(
                                <TextArea maxLength={2000} placeholder={translate('configuration.value.placeholder')} className='input' />
                            )
                        }
                    </Item>}
                    {typeValue === 'html' && <Item hasFeedback={true} label={translate('configuration.value.label')}>
                        {
                            getFieldDecorator('value', value)(
                                <CKEditor />
                            )
                        }
                    </Item>}
                    {
                        typeValue === 'file' &&
                        <Item hasFeedback={true} label={translate('configuration.media.label')}>
                            {
                                getFieldDecorator('file', file)(
                                    <FilePicker
                                        uploadProps={{
                                            accept: '*/*',
                                            multiple: false
                                        }}
                                    >
                                    </FilePicker>
                                )
                            }
                        </Item>}
                    {
                        typeValue === 'boolean' && <Item hasFeedback={true} label={translate('configuration.boolean.label')}>
                            {
                                getFieldDecorator('value', {
                                    ...value,
                                    rules: [],
                                    valuePropName: 'checked'
                                })(
                                    <Checkbox />
                                )
                            }
                        </Item>
                    }
                    <Item hasFeedback={true} label={translate('configuration.description.label')}>
                        {
                            getFieldDecorator('description', description)(
                                <TextArea maxLength={2000}
                                    placeholder={translate('configuration.description.placeholder')}
                                    className='input'
                                />
                            )
                        }
                    </Item>
                </div>
                <div className="actions">
                    <Button htmlType={'submit'} type="primary" className="btn primary-btn">{translate('configuration.submit')}</Button>
                    <Button htmlType={'button'} onClick={hideModal} className="btn red-btn-text">{translate('configuration.cancel')}</Button>
                </div>
            </div>
        </Form>
    )
}
export default view
