import React from "react";
import preProcess from "../../preprocess";
import { Form, Button, Input, message } from "antd";
import { createAction, ActionNames } from "app-redux/actions";

const { create, Item } = Form;
const { TextArea } = Input;
const spinningSelector = ".form";

const Main = (props) => {
  const {
    form,
    data,
    translate,
    hideModal,
    updateRecord,
    createRecord,
    getTableData,
  } = props;

  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const validations = {
    question: {
      rules: [
        {
          required: true,
          message: "Question is Required",
        },
      ],
      initialValue: data && data.question,
    },
    answer: {
      rules: [
        {
          required: true,
          message: "Answer is Required",
        },
      ],
      initialValue: data && data.answer,
    },
  };

  const onSubmit = (event) => {
    event.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      window.startSpinning(spinningSelector);
      try {
        if (data) {
          const { error, payload } = await updateRecord(data.key, values);
          if (error) {
            throw payload.response;
          }
        } else {
          const { error, payload } = await createRecord(values);
          if (error) {
            throw payload.response;
          }
        }
        if (data) {
          message.success("FAQ updated successfully");
        } else {
          message.success("FAQ created successfully");
        }
        if (hideModal instanceof Function) {
          hideModal();
        }
        if (getTableData instanceof Function) {
          getTableData();
        }
      } catch (e) {
        console.log(e);
        if (data) {
          message.error("failed to update FAQ");
        } else {
          message.error("failed to create FAQ");
        }
      }
      window.stopSpinning(spinningSelector);
    });
  };

  const { question, answer } = validations;

  return (
    <div className="form">
      <Form onSubmit={onSubmit}>
        <Item label="Question" hasFeedback>
          {getFieldDecorator(
            "question",
            question
          )(<Input className="input" placeholder={"Enter Question"} />)}
        </Item>
        <Item label="Answer" hasFeedback>
          {getFieldDecorator(
            "answer",
            answer
          )(<TextArea rows={3} placeholder={"Enter Answer"} />)}
        </Item>
        <div className="actions">
          <Button type="primary" className="btn green-btn" htmlType="submit">
            {translate("parts.submit")}
          </Button>
          <Button className="btn red-btn-text" onClick={hideModal}>
            {translate("parts.cancel")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

const bindAction = (dispatch) => {
  return {
    createRecord: (data) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "faq",
          ...data,
        })
      );
    },
    updateRecord: (entityId, data) => {
      return dispatch(
        createAction(ActionNames.UPDATE_ENTITY, {
          entityId,
          entityName: "faq",
          ...data,
        })
      );
    },
  };
};
Main.displayName = "Part-Form";
export default preProcess(create()(Main), {
  localize: true,
  connect: [null, bindAction],
});
