import React, { useState, useEffect } from 'react'
import preProcess from '../../preprocess'
import { Form, Button, Input, message, Select, Switch } from 'antd'
import { createAction, ActionNames } from 'app-redux/actions'
import { getAllISOCodes } from 'iso-country-currency'
import './styles.scss'

import ClientSearch from '../../clientSearch'

const { Option } = Select
const { create, Item } = Form
const spinningSelector = '.form'
const Main = (props) => {
    const {
        form,
        data,
        translate,
        hideModal,
        CURRENCY,
        updateRecord,
        createRecord,
        getTableData,
    } = props
    const { getFieldDecorator, validateFieldsAndScroll } = form
    const { symbol } =
        getAllISOCodes().find((item) => {
            return item.currency === CURRENCY
        }) || {}

    const validations = {
        make: {
            rules: [
                {
                    required: true,
                    message: translate('parts.make.required'),
                },
            ],
            initialValue: data && data.make,
        },
        model: {
            // rules: [{
            //     required: true,
            //     message: translate('parts.model.required')
            // }],
            initialValue: data && data.model,
        },
        colour: {
            // rules: [{
            //     required: true,
            //     message: translate('parts.colour.required')
            // }],
            initialValue: data && data.colour,
        },
        variant: {
            // rules: [{
            //     required: true,
            //     message: translate('parts.variant.required')
            // }],
            initialValue: data && data.variant,
        },
        name: {
            rules: [
                {
                    required: true,
                    message: translate('parts.name.required'),
                },
            ],
            initialValue: data && data.name,
        },
        description: {
            // rules: [{
            //     required: true,
            //     message: translate('parts.description.required')
            // }],
            initialValue: data && data.description,
        },
        price: {
            rules: [
                {
                    required: true,
                    message: translate('parts.price.required'),
                },
            ],
            initialValue: data && data.price,
        },
        product: {
            initialValue: data && data.product,
        },
        enabled: {
            initialValue: data ? !!data.enabled : true,
            valuePropName: 'checked',
        },
    }
    const onSubmit = (event) => {
        event.preventDefault()
        validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return
            }
            window.startSpinning(spinningSelector)
            try {
                if (data) {
                    const { error, payload } = await updateRecord(data.key, values)
                    if (error) {
                        throw payload.response
                    }
                } else {
                    const { error, payload } = await createRecord(values)
                    if (error) {
                        throw payload.response
                    }
                }
                if (data) {
                    message.success(translate('parts.update.success'))
                } else {
                    message.success(translate('parts.create.success'))
                }
                if (hideModal instanceof Function) {
                    hideModal()
                }
                if (getTableData instanceof Function) {
                    getTableData()
                }
            } catch (e) {
                console.log(e)
                if (data) {
                    message.error(translate('parts.update.fail'))
                } else {
                    message.error(translate('parts.create.fail'))
                }
            }
            window.stopSpinning(spinningSelector)
        })
    }

    const {
        make,
        model,
        colour,
        variant,
        name,
        description,
        price,
        product,
        enabled
    } = validations
    return (
        <div className="form">
            <Form onSubmit={onSubmit}>
                <div className="inline">
                    <Item label={translate('parts.name.label')} hasFeedback>
                        {getFieldDecorator(
                            'name',
                            name
                        )(
                            <Input
                                className="input"
                                placeholder={translate('parts.name.placeholder')}
                            />
                        )}
                    </Item>
                    <Item
                        label={translate('parts.price.label') + ' ' + symbol}
                        hasFeedback
                    >
                        {getFieldDecorator(
                            'price',
                            price
                        )(
                            <Input
                                className="input"
                                placeholder={translate('parts.price.placeholder')}
                            />
                        )}
                    </Item>
                    <Item label={translate('parts.make.label')} hasFeedback>
                        {getFieldDecorator(
                            'make',
                            make
                        )(
                            <Input
                                className="input"
                                placeholder={translate('parts.make.placeholder')}
                            />
                        )}
                    </Item>
                </div>
                <div className="inline">
                    <Item label={translate('parts.model.label')} hasFeedback>
                        {getFieldDecorator(
                            'model',
                            model
                        )(
                            <Input
                                className="input"
                                placeholder={translate('parts.model.placeholder')}
                            />
                        )}
                    </Item>
                    <Item label={translate('parts.colour.label')} hasFeedback>
                        {getFieldDecorator(
                            'colour',
                            colour
                        )(
                            <Input
                                className="input"
                                placeholder={translate('parts.colour.placeholder')}
                            />
                        )}
                    </Item>
                    <Item label={translate('parts.variant.label')} hasFeedback>
                        {getFieldDecorator(
                            'variant',
                            variant
                        )(
                            <Input
                                className="input"
                                placeholder={translate('parts.variant.placeholder')}
                            />
                        )}
                    </Item>
                </div>
                <Item label={translate('parts.description.label')} hasFeedback>
                    {getFieldDecorator(
                        'description',
                        description
                    )(
                        <Input
                            className="input"
                            placeholder={translate('parts.description.placeholder')}
                        />
                    )}
                </Item>
                <Item label={translate('parts.product.label')} hasFeedback>
                    {getFieldDecorator(
                        'product',
                        product
                    )(
                        <ClientSearch
                            placeholder="Type to Search Products"
                            entityName="products"
                            noParse={true}
                            formatter={(item) => {
                                const { key, model } = item
                                return {
                                    label: key + ' (' + model + ')',
                                    value: key,
                                }
                            }}
                        />
                    )}
                </Item>
                <Item label={translate('product-services.enabled.label')} hasFeedback>
                    {getFieldDecorator(
                        'enabled',
                        enabled
                    )(
                        <Switch
                            placeholder={translate('product-services.enabled.placeholder')}
                        />
                    )}
                </Item>
                <div className="legends">
                    <ul>
                        <li>
                            <span className="legend">Name</span>
                            <span className="text">Name of the part e.g Tyre</span>
                        </li>
                        <li>
                            <span className="legend">Make</span>
                            <span className="text">Make or Brand of the part e.g Goodyear</span>
                        </li>
                        <li>
                            <span className="legend">Variant</span>
                            <span className="text">Variant of the part e.g Goodyear 184R</span>
                        </li>
                    </ul>
                </div>
                <div className="actions">
                    <Button type="primary" className="btn primary-btn" htmlType="submit">
                        {translate('parts.submit')}
                    </Button>
                    <Button className="btn red-btn-text" onClick={hideModal}>
                        {translate('parts.cancel')}
                    </Button>
                </div>
            </Form>
        </div>
    )
}
const mapStateToProps = ({ config }) => {
    const { configuration } = config || {}
    const { CURRENCY } = configuration || {}
    return {
        CURRENCY,
    }
}
const bindAction = (dispatch) => {
    return {
        createRecord: (data) => {
            return dispatch(
                createAction(ActionNames.CREATE_ENTITY, {
                    entityName: 'parts',
                    ...data,
                })
            )
        },
        updateRecord: (entityId, data) => {
            return dispatch(
                createAction(ActionNames.UPDATE_ENTITY, {
                    entityId,
                    entityName: 'parts',
                    ...data,
                })
            )
        }
    }
}
Main.displayName = 'Part-Form'
export default preProcess(create()(Main), {
    localize: true,
    connect: [mapStateToProps, bindAction],
})
