import React from "react";
import { Tooltip } from "antd";
import "./style.scss";
export default (props) =>{
  let children = props.children;
  children = React.cloneElement(children, {
    disabled : true
  })
  return window.app.isDemoEnabled ? (
    <Tooltip
      title={window.app.demoDisabledText || "Disabled in demo"}
      openClassName="disabled-in-demo"
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
}
