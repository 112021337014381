import React from "react";
import preProcess from "../../preprocess";
import { Form, Button, Input, message, Switch } from "antd";
import { createAction, ActionNames } from "app-redux/actions";
import "./styles.scss";
import AutoSearch from "../../autoSearch";
import { getAllISOCodes } from 'iso-country-currency'
import ClientSearch from "../../clientSearch";
const { create, Item } = Form;
const spinningSelector = ".form";
const Main = (props) => {
  const {
    form,
    data,
    translate,
    hideModal,
    updateRecord,
    createRecord,
    CURRENCY,
    getTableData,
  } = props;
  const { symbol } = getAllISOCodes().find(item => {
    return item.currency === CURRENCY
  }) || {}
  const { getFieldDecorator, validateFieldsAndScroll } = form;
  const validations = {
    title: {
      rules: [
        {
          required: true,
          message: translate("product-services.title.required"),
        },
      ],
      initialValue: data && data.title,
    },
    description: {
      rules: [
        {
          required: true,
          message: translate("product-services.description.required"),
        },
      ],
      initialValue: data && data.description,
    },
    price: {
      rules: [
        {
          required: true,
          message: translate("product-services.price.required"),
        },
      ],
      initialValue: data && data.price,
    },
    enabled: {
      initialValue: data ? !!data.enabled : true,
      valuePropName: "checked",
    },
    product: {
      rules: [
        // {
        //   required: true,
        //   message: translate("product-services.product.required"),
        // },
      ],
      initialValue: data && data.product,
    },
  };
  const onSubmit = (event) => {
    event.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      window.startSpinning(spinningSelector);
      try {
        if (data) {
          const { error, payload } = await updateRecord(data.key, values);
          if (error) {
            throw payload.response;
          }
        } else {
          const { error, payload } = await createRecord(values);
          if (error) {
            throw payload.response;
          }
        }
        if (data) {
          message.success(translate("product-services.update.success"));
        } else {
          message.success(translate("product-services.create.success"));
        }
        if (hideModal instanceof Function) {
          hideModal();
        }
        if (getTableData instanceof Function) {
          getTableData();
        }
      } catch (e) {
        console.log(e);
        if (data) {
          message.error(translate("product-services.update.fail"));
        } else {
          message.error(translate("product-services.create.fail"));
        }
      }
      window.stopSpinning(spinningSelector);
    });
  };
  const { title, description, price, enabled, product } = validations;
  return (
    <div className="form">
      <Form onSubmit={onSubmit}>
        <Item label={translate("product-services.title.label")} hasFeedback>
          {getFieldDecorator(
            "title",
            title
          )(
            <Input
              className="input"
              placeholder={translate("product-services.title.placeholder")}
            />
          )}
        </Item>
        <Item
          label={translate("product-services.description.label")}
          hasFeedback
        >
          {getFieldDecorator(
            "description",
            description
          )(
            <Input
              className="input"
              placeholder={translate(
                "product-services.description.placeholder"
              )}
            />
          )}
        </Item>
        <div className="inline">
          <Item label={translate("product-services.price.label") + ' ' + symbol} hasFeedback>
            {getFieldDecorator(
              "price",
              price
            )(
              <Input
                className="input"
                placeholder={translate("product-services.price.placeholder")}
              />
            )}
          </Item>
          <Item label={translate("product-services.product.label")} hasFeedback>
            {getFieldDecorator(
              "product",
              product
            )(
              <ClientSearch
                placeholder="Type to Search Products"
                entityName="products"
                noParse={true}
                formatter={(item) => {
                  const { key, model } = item;
                  return {
                    label: key + " (" + model + ")",
                    value: key,
                  };
                }}

              />
            )}
          </Item>
        </div>
        <Item label={translate("product-services.enabled.label")} hasFeedback>
          {getFieldDecorator(
            "enabled",
            enabled
          )(
            <Switch
              placeholder={translate("product-services.enabled.placeholder")}
            />
          )}
        </Item>
        {/* <div className="legend">
          <p>Service for the product e.g Denting or Painting</p>
        </div> */}
        <div className="actions">
          <Button type="primary" className="btn primary-btn" htmlType="submit">
            {translate("parts.submit")}
          </Button>
          <Button className="btn red-btn-text" onClick={hideModal}>
            {translate("parts.cancel")}
          </Button>
        </div>
      </Form>
    </div>
  );
};
const mapStateToProps = ({ config }) => {
  const { configuration } = config || {};
  const { CURRENCY } = configuration || {};
  return {
    CURRENCY
  };
};
const bindAction = (dispatch) => {
  return {
    createRecord: (data) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "product-services",
          ...data,
        })
      );
    },
    updateRecord: (entityId, data) => {
      return dispatch(
        createAction(ActionNames.UPDATE_ENTITY, {
          entityId,
          entityName: "product-services",
          ...data,
        })
      );
    },
  };
};
Main.displayName = "Part-Form";
export default preProcess(create()(Main), {
  localize: true,
  connect: [mapStateToProps, bindAction],
});
