import React from "react";
import { formatDate } from "../utils/date-util";
import ResendInvoice from "../containers/resendInvoice";
import MarkInvoiceAsPaid from "../containers/markInvoiceAsPaid";
import InvoiceHeader from "../containers/invoiceHeader";
import { Button, Select, Icon, Tooltip, Row } from "antd";
import BillTo from "../containers/bill-to";
import { ModalTrigger } from "core-components/modal";
import Print from "../components/print";
import { getAllISOCodes } from "iso-country-currency";
import { nFormatter } from "../utils/common";
import withSecurity from "containers/with-security";
import { getServiceCostSummary } from "containers/container-helpers/common";
const { Option } = Select;
export default {
  entityName: "Invoices",
  entity: "invoice",
  deleteAction: false,
  search: {
    filters: [
      {
        title: "Invoice Number",
        type: "input",
        key: "id",
      },
      {
        title: "Is Paid",
        type: "select",
        key: "isPaid",
        options: ["Yes", "No"].map((item, index) => {
          return (
            <Option key={index} value={(item === "Yes").toString()}>
              {item}
            </Option>
          );
        }),
      },
      {
        title: "Customer Name",
        type: "input",
        key: "customerName",
      },
      {
        title: "Contact Number",
        type: "input",
        key: "mobileNumber",
      },
      {
        title: "Invoice Date",
        type: "date",
        key: "createdDate",
      },
    ],
    searchOnSubmit: true,
  },
  defaultSort: {
    sort: "timestamp",
    sortType: "desc",
  },
  columns: ({ sharedState }) => {
    const { config } = sharedState || {};
    const { configuration } = config || {};
    const { CURRENCY } = configuration || {};
    const { symbol } =
      getAllISOCodes().find((item) => {
        return item.currency === CURRENCY;
      }) || {};
    return [
      {
        title: "Invoice Number",
        dataIndex: "id",
      },
      {
        title: "Date",
        dataIndex: "date",
        render: (date) => <p>{formatDate(date, "DD/MM/YYYY")}</p>,
      },
      {
        title: "Invoice Amount",
        dataIndex: "totalPayable",
        render: (totalPayable) => {
          return <p>{symbol + nFormatter(totalPayable)}</p>;
        },
      },
      {
        title: "Customer Name",
        dataIndex: "customer",
        render: (customer, { customerName }) => {
          if (typeof customer === "object") {
            customer = customer.customerName;
          } else {
            customer = customerName;
          }
          return <p>{customer}</p>;
        },
      },
      {
        title: "Is Paid",
        dataIndex: "paid",
        render: (payment) => {
          return <p>{!!payment ? "Paid" : "Not Paid"}</p>;
        },
      },
    ];
  },
  pagination: {
    type: "server",
  },
  headerActions: [
    ({ props }) => {
      return (
        <div className="invoice-header">
          <Row gutter={16}>
            <InvoiceHeader {...props} />
          </Row>
        </div>
      );
    },
  ],
  rowActions: [
    MarkInvoiceAsPaid,
    ResendInvoice,
    ({ record }) => {
      const { key, customer, date, parts, labourTransportCosts } = record;

      return (
        <Print
          template="invoice"
          data={{
            invoiceKey: key,
            ...record,
            customer:
              typeof customer === "string"
                ? {
                    ...record,
                    customerKey: customer,
                  }
                : customer,
            invoiceKey: record.key,
            date: formatDate(date, "DD-MM-YYYY"),
            ...getServiceCostSummary(record),
          }}
        />
      );
    },
    withSecurity(
      (props) => {
        const { record } = props;
        return (
          <ModalTrigger
            content={<BillTo record={record} />}
            modalProps={{
              title: "Bill To",
              footer: null,
            }}
          >
            <Tooltip placement="top" title={"Send To"}>
              <Button className="btn light-blue-btn-text">
                <Icon type="idcard" />
              </Button>
            </Tooltip>
          </ModalTrigger>
        );
      },
      {
        permissions: ["INVOICE_MANAGE"],
      }
    ),
  ],
  reducer: {
    stateKeys: ["config"],
  },
};
