import React from 'react'

import { formatDate } from '../utils/date-util'
import { Button, Select, Icon, Tooltip } from 'antd'
import SendJobForm from '../containers/send-service'
import ServiceForm from '../containers/forms/service-form'
import { ModalTrigger } from 'core-components/modal'
import Print from '../components/print'
import { getServiceCostSummary } from 'containers/container-helpers/common'
import withSecurity from 'containers/with-security'

const {Option} = Select
export const ViewJob = ({record}) => {
    return (
        <ModalTrigger
            content={<ServiceForm view={true} data={record}/>}
            modalProps={{
                className: 'add-record-modal ServiceForm',
                footer: null,
                title: 'View',
            }}
        >
            <Tooltip placement="top" title={'View'}>
                <Button className="btn orange-btn-text">
                    <Icon type="eye"/>
                </Button>
            </Tooltip>
        </ModalTrigger>
    )
};

export const SendJob = ({record}) => {
    return (
        <ModalTrigger
            content={<SendJobForm record={record}/>}
            modalProps={{
                footer: null,
                title: "Send Email/SMS",
                className:'send-job-form-modal small-width'
            }}
        >
            <Tooltip placement="top" title={'Send Email or SMS'}>
                <Button className="btn orange-btn-text">
                    <Icon type="mail"/>
                </Button>
            </Tooltip>
        </ModalTrigger>
    )
};

export const PrintJob = ({record}) => {
    const {key, customer, date} = record
    return (
        <Print
            template="service"
            data={{
                invoiceKey: key,
                serviceKey: key,
                ...record,
                customer:
                    typeof customer === 'string'
                        ? {
                            ...record,
                            customerKey: customer,
                        }
                        : customer,
                invoiceKey: record.key,
                ...getServiceCostSummary(record),
                date: formatDate(date, 'DD-MM-YYYY'),
            }}
        />
    )
}

export default {
    entityName: 'Service Jobs',
    entity: 'service',
    formId: 'ServiceForm',

    search: (props) => {
        const {location} = props
        const {query} = location || {}
        const {jobStatus} = query || {}
        return {
            ...(jobStatus
                ? {
                    isSubmitted: true,
                    selectedFilter: 11,
                    value: jobStatus,
                }
                : {}),
            filters: [
                {
                    title: 'Customer Name',
                    type: 'input',
                    key: 'customerName',
                },
                {
                    title: 'Month/Year',
                    key: 'monthYear',
                    type: 'input',
                    props: {
                        placeholder: 'MM/YYYY',
                    },
                },
                {
                    title: 'Year',
                    key: 'year',
                    type: 'input',
                },
                {
                    title: 'Month',
                    key: 'month',
                    type: 'input',
                },
                {
                    title: 'Serial Number',
                    type: 'input',
                    key: 'serialNumber',
                },
                {
                    title: 'EW/Customer/Corporate Number',
                    type: 'input',
                    key: 'corporateNumber',
                },
                {
                    title: 'Job Number',
                    type: 'input',
                    key: 'key',
                },
                {
                    title: 'Model',
                    type: 'input',
                    key: 'model',
                },
                {
                    title: 'Mobile Number',
                    type: 'input',
                    key: 'customer',
                },
                {
                    title: 'Home Number',
                    type: 'input',
                    key: 'homeNumber',
                },
                {
                    title: 'Office Number',
                    type: 'input',
                    key: 'officeNumber',
                },
                {
                    title: 'Status',
                    key: 'jobStatus',
                    type: 'select',
                    options: [
                        'Cancelled',
                        'Completed',
                        'Follow Up Required',
                        'Pending',
                    ].map((status, index) => {
                        return (
                            <Option key={index} value={status}>
                                {status}
                            </Option>
                        )
                    }),
                },
                {
                    title: 'Phone Number',
                    type: 'input',
                    key: 'phoneNumber',
                },
            ],
            searchOnSubmit: true,
        }
    },
    pagination: {
        type: 'server',
    },
    columns: [
        {
            title: 'Customer Name',
            dataIndex: 'customer',
            key: 'customerName',
            render: (customer, {customerName}) => {
                if (typeof customer === 'object') {
                    customer = customer.customerName
                } else {
                    customer = customerName
                }
                return <p>{customer}</p>
            },
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date) => {
                return <p>{formatDate(date)}</p>
            },
        },
        {
            title: 'Job Number',
            dataIndex: 'key',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'customerKey',
            key: 'customerKey',
        },
        {
            title: 'Status',
            dataIndex: 'jobStatus',
        },
    ],
    defaultSort: {
        sort: 'timestamp',
        sortType: 'desc',
    },
    rowActions: [
        ViewJob,
        withSecurity(SendJob, {
            permissions: ['SERVICE_EDIT'],
        }),
        PrintJob,
    ],
}
