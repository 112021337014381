import React from 'react'
import './styles.scss'
import { Layout, Icon, Button, Form, Input, Menu, Drawer } from 'antd'
import Link from 'core-components/link'
import { ModalTrigger } from 'core-components/modal'

import tick from '../../../images/tick.svg';
import BookingForm from '../../forms/booking-form';
import scrollToElement from 'scroll-to-element';
//const { SubMenu } = Menu;

//import ChangeLanguage from 'core-components/change-language'

const { Header } = Layout
var view = function () {
    const { scrolled } = this.state
    const { WEBSITELOGO, translate } = this.props
    const route = this.getCurrentRoute()

    let isSubmitted = true;

    const confirm = <div>
        <object type="image/svg+xml" data={tick}></object>
        <p className="descripiton">Thanks for connectiong with us. <br />We will get back to you within 24/48 hours.</p>
    </div>;

    const onMenuClick = (item)=>{
        if(this.state.menuVisible){
            this.closeMobileMenu()
        }
        scrollToElement(item)        
    }

    return (
        <div>
            <Header
                className={'public-header fixed ' + (scrolled ? 'scrolled animated slideInDown ' : '') + (route.routeKey === 'landing' ? 'landing' : '') + (route.routeKey === 'login' || 'forgetPassword' || 'support' ? 'scrolled' : '')}>
                <div className="graphics">
                    <svg width="980" height="174" viewBox="0 0 980 174" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="1121.98" height="257.534" rx="128.767" transform="matrix(0.997497 -0.0707145 0.0704636 0.997514 -7.86328 -74.7695)" fill="#F18B6F" />
                    </svg>
                </div>
                <div className="bottom-section">
                    <div className="container">
                        <div className="content">
                            <div className="left">
                                <Button className="menu-btn" onClick={this.openMobileMenu}>
                                    <Icon type="menu" />
                                </Button>
                                <div className="logo-container">
                                    <Link routeKey="landing" className="logo">
                                        {WEBSITELOGO && <img src={WEBSITELOGO} alt={'logo'} />}
                                    </Link>
                                </div>
                            </div>
                            <div className="right">
                                <Menu className="top-menu" onClick={this.closeMobileMenu}>
                                    <Menu.Item>
                                        <a onClick={() => onMenuClick("#hero")} href="#hero">Home</a>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <a onClick={() => onMenuClick("#services")} href="#services">Services</a>
                                    </Menu.Item>
                                    {/* <Menu.Item>
                                        <a onClick={() => onMenuClick("#testimonials")}>Testimonials</a>
                                    </Menu.Item> */}
                                    <Menu.Item>
                                        <a onClick={() => onMenuClick("#about")} href="#about">About Us</a>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <a onClick={() => onMenuClick("#faq")} href="#faq">FAQ</a>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <a onClick={() => onMenuClick("#contact")} href="#contact">Contact Us</a>
                                    </Menu.Item>
                                </Menu>
                                <span className="separator" />
                                <div className="actions">
                                    <Link routeKey="login" className="login-link">
                                        <Button className="primary-btn header-btn login-btn">Login</Button>
                                    </Link>
                                    <ModalTrigger
                                        content={isSubmitted ? <BookingForm /> : confirm}
                                        modalProps={{
                                            title: isSubmitted ?
                                                <span>Book Your Appointment</span>
                                                :
                                                <span>Confirm Appointment</span>,
                                            className: isSubmitted ?
                                                "book-appointment-modal" : "confirm-modal",
                                            footer: null,

                                        }}
                                    >
                                        <Button className="primary-btn header-btn booking-btn">Book Appointment</Button>
                                    </ModalTrigger>
                                    <a href='https://edocsify.com/docs/auto-repair-crm' target="_blank" className="documentation-link">
                                        <Button className="primary-btn header-btn login-btn">Documentation</Button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Header >
            <Drawer
                title={false}
                placement="left"
                closable={true}
                onClose={this.closeMobileMenu}
                visible={this.state.menuVisible}
                key="left"
                className="public-drawer"
            >
                <div className="mobile-menu">
                    <Menu >
                        <Menu.Item>
                            <a onClick={() => onMenuClick("#hero")} href="#hero">Home</a>
                        </Menu.Item>
                        <Menu.Item>
                            <a onClick={() => onMenuClick("#services")} href="#services">Services</a>
                        </Menu.Item>
                        <Menu.Item>
                            <a onClick={() => onMenuClick("#testimonials")}>Testimonials</a>
                        </Menu.Item>
                        <Menu.Item>
                            <a onClick={() => onMenuClick("#about")} href="#about">About Us</a>
                        </Menu.Item>
                        <Menu.Item>
                            <a onClick={() => onMenuClick("#faq")} href="#faq">FAQ</a>
                        </Menu.Item>
                        <Menu.Item>
                            <a onClick={() => onMenuClick("#contact")} href="#contact">Contact Us</a>
                        </Menu.Item>
                    </Menu>
                    <div className="actions">
                        <Link routeKey="login" className="login-link">
                            <Button className="primary-btn-text login-btn" onClick={this.closeMobileMenu}>Login</Button>
                        </Link>
                        <ModalTrigger
                            content={isSubmitted ? <BookingForm /> : confirm}
                            modalProps={{
                                title: isSubmitted ?
                                    <span>Book Your Appointment</span>
                                    :
                                    <span>Confirm Appointment</span>,
                                className: isSubmitted ?
                                    "book-appointment-modal" : "confirm-modal",
                                footer: null,

                            }}
                        >
                            <Button className="primary-btn booking-btn">Book Appointment</Button>
                        </ModalTrigger>
                    </div>
                </div>
            </Drawer>
        </div >
    )
}
export default view
