import React from "react";
import "./styles.scss";
import { Row, Col, Icon, Button } from "antd";
import Link from "../../../components/link";
import InvoiceHeader from "../../invoiceHeader";
var view = function () {
  const { dashboard } = this.props;
  let { customers, users, service } = dashboard || {};
  service = service || {};
  const cancelledJobs = service["Cancelled"] || 0;
  const completedJobs = service["Completed"] || 0;
  const followUpRequiredJobs = service["Follow Up Required"] || 0;
  const pendingJobs = service["Pending"] || 0;
  const array = [
    {
      count: users,
      label: "Users",
      route: "home.users",
      icon: "team",
    },
    {
      count: customers,
      label: "Customers",
      route: "home.customer",
      icon: "solution",
    },
    {
      count: followUpRequiredJobs,
      label: "Follow Up Jobs",
      route: "home.service",
      jobStatus: "Follow Up Required",
      icon: "hdd",
    },
    {
      count: cancelledJobs,
      label: "Cancelled Jobs",
      route: "home.service",
      jobStatus: "Cancelled",
      icon: "exception",
    },
    {
      count: completedJobs,
      label: "Completed jobs",
      route: "home.service",
      jobStatus: "Completed",
      icon: "file-done",
    },
    {
      count: pendingJobs,
      label: "Pending Jobs",
      route: "home.service",
      jobStatus: "Pending",
      icon: "file-sync",
    },
  ];
  return (
    <div className="dashboard">
      <div className="content">
        <p className="page-title mobile-only">Dashboard</p>
        <Row gutter={16}>
          {array.map((item, index) => {
            const { count, label, route, jobStatus } = item;
            return (
              <Col key={index} xs={24} sm={12} md={12} lg={12} xl={6}>
                <Link
                  routeKey={route}
                  {...(jobStatus && { queryParams: { jobStatus } })}
                >
                  <div className="card">
                    <div className="info">
                      <div className="icon">
                        <Icon type={item.icon} />
                      </div>
                      <div className="details">
                        <p className="count">{count || 0}</p>
                        <p className="label">{label}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
            );
          })}
          <InvoiceHeader enableClick={true} />
        </Row>
      </div>
    </div>
  );
};
export default view;
