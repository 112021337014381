import { mobileAndTabletcheck } from "../../utils/web";

export default ()=>{
    const check = mobileAndTabletcheck();
    return {
        ui : {
            horizontalMenu : false,
            drawer : !check
        }
    }
    
}
