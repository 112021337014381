import React from "react";
import "./styles.scss";
import { Form, Input, Avatar, Button, Checkbox, Select } from "antd";
import FilePicker from "../../../components/file-picker";

const CheckboxGroup = Checkbox.Group;
const FormItem = Form.Item;
const options = [
  { label: "Give admin rights to this user", value: "set-admin" },
];

var view = function () {
  const { translate, form, hideModal, data } = this.props;
  const { roles } = this.state;
  const { providerData } = data || {};
  const [provider] = providerData || [];
  const { providerId } = provider || {};
  const isSocial = providerId && providerId !== "password";
  const { name, email, password, role, photoURL } = this.validations;
  const { getFieldDecorator } = form;

  return (
    <Form onSubmit={this.handleSubmit.bind(this)}>
      <div className="new-form user">
        <div className="form">
          {data ? (
            <FormItem
              hasFeedback={true}
              label={translate("user.phototURL.label")}
            >
              <div className="image">
                {getFieldDecorator("photoURL", photoURL)(<FilePicker />)}
              </div>
            </FormItem>
          ) : null}
          <div className="inline">
            <FormItem hasFeedback={true} label={translate("user.name.label")}>
              {getFieldDecorator(
                "name",
                name
              )(
                <Input
                  maxLength={200}
                  type={"text"}
                  placeholder={translate("common.firstName.placeholder")}
                  className="input"
                />
              )}
            </FormItem>
            {!(data && data.role === "CUSTOMER") && (
              <FormItem hasFeedback={true} label={translate("user.role.label")}>
                {getFieldDecorator(
                  "role",
                  role
                )(
                  <Select className="select">
                    <Select.Option value={""}>Select Role</Select.Option>
                    {roles.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.code}>
                          {item.description}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
            )}
          </div>
          {!isSocial && (
            <div className="inline">
              <FormItem
                hasFeedback={true}
                label={translate("user.email.label")}
              >
                {getFieldDecorator(
                  "email",
                  email
                )(
                  <Input
                    maxLength={100}
                    placeholder={translate("common.email.placeholder")}
                    className="input"
                  />
                )}
              </FormItem>
              <FormItem
                hasFeedback={true}
                label={translate("user.password.label")}
              >
                {getFieldDecorator(
                  "password",
                  password
                )(
                  <Input
                    maxLength={200}
                    type={"password"}
                    placeholder={translate("common.password.placeholder")}
                    className="input"
                  />
                )}
              </FormItem>
            </div>
          )}
          {/*<FormItem hasFeedback={false} label={'Select Options'}>*/}
          {/*<div className="options">*/}
          {/*{*/}
          {/*getFieldDecorator('options')(*/}
          {/*<CheckboxGroup options={options} defaultValue={['password']}/>*/}
          {/*)*/}
          {/*}*/}
          {/*</div>*/}
          {/*</FormItem>*/}
        </div>
        <div className="actions">
          <Button htmlType={"submit"} className="btn primary-btn save">
            {translate("user.save")}
          </Button>
          <Button
            htmlType={"button"}
            className="btn red-btn-text"
            onClick={hideModal}
          >
            {translate("user.cancel")}
          </Button>
        </div>
      </div>
    </Form>
  );
};
export default view;
