import React from "react";
export default {
  entity: "testimonials",
  entityName: "Testimonials",
  formId: "TestimonialsForm",
  pagination: {
    type: "server",
  },
  defaultSort: {
    sort: "createdAt",
    sortType: "desc",
  },
  search: {
    searchOnSubmit: true,
    filters: [
      {
        title: "Name",
        type: "input",
        key: "name",
      },
      {
        title: "Description",
        type: "input",
        key: "description",
      },
      {
        title: "Designation",
        type: "input",
        key: "designation",
      },
      {
        title: "Image",
        type: "input",
        key: "image",
      },
    ],
  },
  columns: ({ sharedState: { translate }, getTableData }) => [
    {
      dataIndex: "name",
      title: "Name",
    },
    {
      dataIndex: "description",
      title: "Description",
    },
    {
      dataIndex: "designation",
      title: "Designation",
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (value, { type }) => {
        return (
          value && (
            <div>
              <div className="image">
                <img
                  src={value}
                  style={{
                    width: 100,
                  }}
                />
              </div>
            </div>
          )
        );
      },
    },
  ],
};
