import React from 'react';
import './styles.scss';

/**
 * @description Wave
 * @type Component
 * @author Inderdeep
 */
const Main = () => {
  return (
    <div className="wave">
      <svg viewBox="0 0 500 150" preserveAspectRatio="none">
        <path d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z"></path>
      </svg>
    </div>
  );
};

export default Main;
