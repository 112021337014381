import React from 'react'
import './styles.scss'
import { Form, Input, Button, Checkbox } from 'antd';

const { Item } = Form
const { TextArea } = Input
var view = function () {
    const { form, hideModal, data, translate } = this.props
    const { key, apiKey, apiSecret, enabled, additionalData } = this.validations
    const { getFieldDecorator } = form
    return (
        <Form onSubmit={this.handleSubmit.bind(this)}>
            <div className="new-form">
                <div className="form">
                    <Item hasFeedback={true} label={translate('payment.enabled.label')}>
                        {
                            getFieldDecorator('enabled', enabled)(
                                <Checkbox />
                            )
                        }
                    </Item>
                    <Item hasFeedback={true} label={translate('payment.key.label')}>
                        {
                            getFieldDecorator('key', key)(
                                <Input maxLength={100}
                                    disabled={!!data}
                                    className='input uppercase'
                                    placeholder={translate('payment.key.placeholder')}
                                />
                            )
                        }
                    </Item>
                    {
                        data && data.url && <div>
                            <a href={data.url} target='_blank'>{translate('payment.documentation')}</a>
                        </div>
                    }
                    <Item>
                        {
                            getFieldDecorator('apiKey', apiKey)(
                                <Input maxLength={200} className='input' placeholder={translate('payment.apiKey.label')} />
                            )
                        }
                    </Item>
                    <Item>
                        {
                            getFieldDecorator('apiSecret', apiSecret)(
                                <Input maxLength={200} className='input' placeholder={translate('payment.apiSecret.label')} />
                            )
                        }
                    </Item>
                    <Item>
                        {
                            getFieldDecorator('additionalData', additionalData)(
                                <TextArea maxLength={2000} placeholder={translate('payment.additionalData.label')} />
                            )
                        }
                    </Item>
                </div>
                <div className="actions">
                    <Button htmlType={'submit'} type="primary" className="btn primary-btn">{translate('payment.save')}</Button>
                    <Button htmlType={'button'} onClick={hideModal} className="btn red-btn-text">{translate('payment.cancel')}</Button>
                </div>
            </div>
        </Form>
    )
}
export default view
