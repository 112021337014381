import service, { ViewJob, SendJob, PrintJob } from "./service";
import withSecurity from "containers/with-security";
export default {
  entityName: "My Service Jobs",
  getRequestParams : ()=>{
    return {
      current : true
    }
  },
  formId: "ServiceForm",
  ...service,
  extraSecurityCheck: (allowedPermissions, allowedRoles, permissions, role) => {
    return role !== "admin";
  },
  rowActions: [ViewJob, withSecurity(SendJob, ["MY-JOBS_EDIT"]), PrintJob],
};
