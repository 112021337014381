export function convertPriceToCurrency(price) {
  if (price && price !== "") {
    return `${window.app.currencySymbol} ${price}`;
  }
  return "";
}

export function getServiceCostSummary(service) {
  const { parts, services, labourTransportCosts, discount } = service;
  const totalPartsCost = (parts || []).reduce((sum, item) => {
    const { partCost } = item;
    return sum + parseFloat(partCost);
  }, 0);
  const totalServicesCost = (services || []).reduce((sum, item) => {
    const { price } = item;
    return sum + parseFloat(price);
  }, 0);
  const totalPartsAndServicesCost = parseFloat(
    (totalPartsCost + totalServicesCost).toFixed()
  );
  const total = parseFloat(
    (totalPartsAndServicesCost + (labourTransportCosts || 0)).toFixed(2)
  );
  const totalPayable = parseFloat(
    (total - (discount || 0)).toFixed(2)
  );
  return {
    totalPartsCost,
    totalServicesCost,
    totalPartsAndServicesCost,
    total,
    totalPayable,
    discount: (discount || 0) //? `${discount} %` : "",
  };
}
