import React from 'react'
import './styles.scss'
import {
    Form,
    Input,
    InputNumber,
    Icon,
    Button,
    Select,
    DatePicker,
    Row,
    Col
} from 'antd'
import FilePicker from 'core-components/file-picker'
import CustomerForm from '../customer-form'
import SignatureCapture from 'core-components/signature-capture'
import { getAllISOCodes } from 'iso-country-currency'
import AutoSearch from '../../autoSearch'
import ClientSearch from '../../clientSearch'
import { parseJSON } from 'utils/web'
import { isUserAllowed } from '../../with-security'

const { TextArea } = Input
const { Item } = Form
const { Option } = Select

var view = function () {
    this.setValidations()
    const {
        form,
        hideModal,
        data,
        translate,
        fetchingCustomers,
        view,
        role,
        permissions,
        CURRENCY,
    } = this.props
    const { symbol } =
        getAllISOCodes().find((item) => {
            return item.currency === CURRENCY
        }) || {}
    const {
        customerProducts,
        customers,
        partReactKey,
        serviceReactKey,
    } = this.state
    const {
        customer,
        key,
        date,
        jobStatus,
        sign,
        faultReported,
        faultDiagnosed,
        serviceDetails,
        repairCompletedDate,
        labourTransportCosts,
        generalRemarks,
        attachment,
        corporateNumber,
        parts,
        services,
        product,
        assignedTo,
        discount,
    } = this.validations
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form
    let initialCustomerValue = ''
    if (data) {
        initialCustomerValue =
            typeof data.customer === 'object' ? 'create-new' : data.customer
    }
    const jobStatuses = data
        ? ['Pending', 'Follow Up Required', 'Completed', 'Cancelled']
        : ['Pending', 'Follow Up Required']
    const customerValue = getFieldValue('customer') || initialCustomerValue
    let productValue = getFieldValue('product') || (data && data.product)
    if (productValue) {
        productValue = parseJSON(productValue)
    }
    const statusValue = getFieldValue('jobStatus') || (data && data.jobStatus)
    const disabled = (data && data.jobStatus === 'Completed') || view
    const partsValue = getFieldValue('parts') || (data && data.parts) || []
    const discountValue = getFieldValue('discount') || 0
    const servicesValue =
        getFieldValue('services') || (data && data.services) || []
    const labourTransportCostsValue =
        getFieldValue('labourTransportCosts') ||
        (data && data.labourTransportCosts)
    const array = partsValue.map((item) => {
        const { partName, partCost } = item
        return {
            label: partName,
            value: parseFloat(partCost),
        }
    })
    if (servicesValue) {
        servicesValue.map((item) => {
            const { title, price } = item
            array.push({
                label: title,
                value: price,
            })
        })
    }
    if (labourTransportCostsValue) {
        array.push({
            label: 'Labour Transport Costs',
            value: parseFloat(labourTransportCostsValue),
        })
    }
    const total = array.reduce((sum, item) => {
        const { value } = item
        return sum + value
    }, 0)
    return (
        <div className="new-form form service">
            <Form onSubmit={this.handleSubmit.bind(this)}>
                {data && data.jobStatus === 'Completed' && (
                    <p className="label done">
                        <Icon type="check-circle" /> This job is already completed. It can
                        not be edited.
                    </p>
                )}
                <div className="section">
                    <p className="label">Service Job Details</p>
                    <div className="content">
                        <div className="inline">
                            <Item hasFeedback={true} label="EW/Customer/Corporate Number">
                                {getFieldDecorator(
                                    'corporateNumber',
                                    corporateNumber
                                )(
                                    <Input
                                        disabled={disabled}
                                        className="input"
                                        placeholder="Enter Number"
                                    />
                                )}
                            </Item>
                            <Item hasFeedback={true} label="Job Number">
                                {getFieldDecorator(
                                    'key',
                                    key
                                )(
                                    <Input
                                        disabled={disabled}
                                        className="input"
                                        placeholder="Enter Job Number"
                                    />
                                )}
                            </Item>
                            <Item hasFeedback={true} label="Customer Name">
                                {getFieldDecorator(
                                    'customer',
                                    customer
                                )(
                                    <Select
                                        showSearch={true}
                                        onSearch={this.onSearch}
                                        onChange={this.getCustomerDetails}
                                        disabled={disabled}
                                        className="select"
                                        placeholder="Search by Name or Phone"
                                        showArrow={false}
                                        filterOption={false}
                                        loading={fetchingCustomers}
                                    >
                                        {[
                                            ...(customers || []).map((customer, index) => {
                                                const { key, customerName } = customer
                                                return (
                                                    <Option key={index} value={key}>
                                                        {customerName + ' (' + key + ')'}
                                                    </Option>
                                                )
                                            }),
                                        ]}
                                        {isUserAllowed(
                                            {
                                                permissions: ['CUSTOMER_CREATE'],
                                            },
                                            {
                                                permissions,
                                                role,
                                            }
                                        ) && (
                                                <Option
                                                    placeholder="Search Customer By Mobile"
                                                    value={'create-new'}
                                                    key="create-new"
                                                >
                                                    {'Or Create New Customer'}
                                                </Option>
                                            )}
                                    </Select>
                                )}
                            </Item>
                            <Item hasFeedback={true} label="Date">
                                {getFieldDecorator(
                                    'date',
                                    date
                                )(<DatePicker disabled={disabled} />)}
                            </Item>
                        </div>
                        {(customerValue === 'create-new' || view || disabled) && (
                            <div className="appended-form">
                                <p>Customer Form</p>
                                <CustomerForm
                                    service={true}
                                    disabled={disabled}
                                    translate={translate}
                                    onProductChange={(products) => {
                                        this.setState({ customerProducts: products })
                                    }}
                                    data={(function () {
                                        try {
                                            if (!data) {
                                                return
                                            }
                                            let customer = {
                                                ...data,
                                            }
                                            if (typeof data.customer === 'string') {
                                                let customerData = customers.find(
                                                    (item) => item.key === data.customer
                                                )
                                                customer = {
                                                    ...customerData,
                                                    ...data,
                                                }
                                            } else {
                                                customer = {
                                                    ...customer,
                                                    ...data.customer,
                                                }
                                            }
                                            if (data.customerKey) {
                                                customer = {
                                                    ...customer,
                                                    key: data.customerKey,
                                                }
                                            }
                                            return customer
                                        } catch (e) {
                                            console.log(e)
                                        }
                                    })()}
                                    wrappedComponentRef={(ref) => {
                                        this.customerFormRef = ref
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {customerValue && (
                    <div className="section">
                        <p className="label">Product Details</p>
                        <div className="content">
                            <div className="inline">
                                <Item label="Product" hasFeedback>
                                    {getFieldDecorator(
                                        'product',
                                        product
                                    )(
                                        <Select
                                            className="select"
                                            placeholder="Select Product"
                                            disabled={disabled}
                                            onChange={() => {
                                                this.setState({
                                                    partReactKey: Math.random(),
                                                    serviceReactKey: Math.random(),
                                                })
                                                setFieldsValue({ parts: [], services: [] })
                                            }}
                                        >
                                            {customerProducts.map((item, index) => {
                                                const { key, model } = item
                                                return (
                                                    <Option key={index} value={JSON.stringify(item)}>
                                                        {key + ' (' + model + ')'}
                                                    </Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </Item>
                            </div>
                        </div>
                    </div>
                )}
                {productValue && (
                    <React.Fragment>
                        <div className="section">
                            <p className="label">Job Status</p>
                            <div className="content">
                                <div className="inline">
                                    <Item hasFeedback={true} label="Job Status">
                                        {getFieldDecorator(
                                            'jobStatus',
                                            jobStatus
                                        )(
                                            <Select
                                                disabled={disabled}
                                                className="select"
                                                placeholder={'Select Job Status'}
                                            >
                                                {jobStatuses.map((status, index) => {
                                                    return (
                                                        <Option key={index} value={status}>
                                                            {status}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        )}
                                    </Item>
                                </div>
                                {statusValue === 'Completed' && (
                                    <Item hasFeedback={true} label="Signature">
                                        {getFieldDecorator(
                                            'sign',
                                            sign
                                        )(<SignatureCapture disabled={disabled} />)}
                                    </Item>
                                )}
                            </div>
                        </div>
                        <div className="section">
                            <p className="label">General Details</p>
                            <div className="content">
                                <Item label="Parts" hasFeedback>
                                    {getFieldDecorator(
                                        'parts',
                                        parts
                                    )(
                                        <ClientSearch
                                            disabled={disabled}
                                            key={partReactKey} //SetFieldsValue and resetFields also doesn't work somehow  without random key
                                            placeholder="Select Parts"
                                            entityName="parts"
                                            searchField="name"
                                            filterData={(part) => {
                                                const { product } = part
                                                if (product && product.length) {
                                                    return (
                                                        part.enabled &&
                                                        product.indexOf(productValue.key) !== -1
                                                    )
                                                }
                                                return true
                                            }}
                                            formatter={(item) => {
                                                const { key, make, price, name } = item
                                                const value = {
                                                    partCost: price,
                                                    partKey: key,
                                                    partName: name,
                                                }
                                                return {
                                                    label: name + ' (' + make + ')',
                                                    value,
                                                }
                                            }}
                                        />
                                    )}
                                </Item>
                                <div className="inline">
                                    <Item label="Services" hasFeedback>
                                        {getFieldDecorator(
                                            'services',
                                            services
                                        )(
                                            <ClientSearch
                                                disabled={disabled}
                                                key={serviceReactKey} //SetFieldsValue and resetFields also doesn't work somehow  without random key
                                                placeholder="Select Services"
                                                entityName="product-services"
                                                searchField="title"
                                                keyName="key"
                                                filterData={(service) => {
                                                    const { product } = service
                                                    if (product && product.length) {
                                                        return (
                                                            service.enabled &&
                                                            product.indexOf(productValue.key) !== -1
                                                        )
                                                    }
                                                    return true
                                                }}
                                                formatter={(item) => {
                                                    const { key, title, price } = item
                                                    const value = {
                                                        key,
                                                        price: parseFloat(price),
                                                        title,
                                                    }
                                                    return {
                                                        label: title,
                                                        value,
                                                    }
                                                }}
                                            />
                                        )}
                                    </Item>
                                    {((permissions || []).indexOf('SERVICE_EDIT') !== -1 || (role || '').toLowerCase() === 'admin') && (
                                        <Item label="Assigned To" hasFeedback>
                                            {getFieldDecorator(
                                                'assignedTo',
                                                assignedTo
                                            )(
                                                <AutoSearch
                                                    disabled={disabled}
                                                    placeholder="Type to Search Assignees"
                                                    entityName="users"
                                                    searchField="displayName"
                                                    formatter={(item) => {
                                                        const { uid, displayName, email } = item
                                                        return {
                                                            label: `${displayName} (${email})`,
                                                            value: uid,
                                                        }
                                                    }}
                                                    filterData={({ role, emailVerified }) => {
                                                        return (
                                                            !!emailVerified &&
                                                            (role || '').toLowerCase() !== 'admin' &&
                                                            (role || '').toLowerCase() !== 'customer'
                                                        )
                                                    }}
                                                />
                                            )}
                                        </Item>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <p className="label">Repair Details</p>
                            <div className="content">
                                <div className="inline">
                                    <Item hasFeedback={true} label="Fault Reported">
                                        {getFieldDecorator(
                                            'faultReported',
                                            faultReported
                                        )(
                                            <TextArea
                                                disabled={disabled}
                                                placeholder="Enter Fault Reported"
                                                className="input"
                                            />
                                        )}
                                    </Item>
                                    <Item hasFeedback={true} label="Fault Diagnosed">
                                        {getFieldDecorator(
                                            'faultDiagnosed',
                                            faultDiagnosed
                                        )(
                                            <TextArea
                                                disabled={disabled}
                                                placeholder="Enter Fault Diagnosed"
                                                className="input"
                                            />
                                        )}
                                    </Item>
                                    <Item hasFeedback={true} label="Service Details">
                                        {getFieldDecorator(
                                            'serviceDetails',
                                            serviceDetails
                                        )(
                                            <TextArea
                                                disabled={disabled}
                                                placeholder="Enter Details"
                                                className="input"
                                            />
                                        )}
                                    </Item>
                                </div>
                            </div>
                        </div>

                        <div className="section">
                            <p className="label">Cost Completion</p>
                            <div className="content">
                                <div className="inline">
                                    <Item
                                        hasFeedback={true}
                                        label={'Labour Transport Costs (' + symbol + ')'}
                                    >
                                        {getFieldDecorator(
                                            'labourTransportCosts',
                                            labourTransportCosts
                                        )(
                                            <InputNumber
                                                disabled={disabled}
                                                className="input"
                                                placeholder="Enter Labour Transport Cost"
                                            />
                                        )}
                                    </Item>
                                    <Item hasFeedback={true} label="Repair Complete Date">
                                        {getFieldDecorator(
                                            'repairCompletedDate',
                                            repairCompletedDate
                                        )(<DatePicker disabled={disabled} />)}
                                    </Item>
                                </div>
                            </div>
                        </div>

                        <div className="section">
                            <p className="label">General Remarks</p>
                            <div className="content">
                                <Item hasFeedback={true} label="Enter Remarks">
                                    {getFieldDecorator(
                                        'generalRemarks',
                                        generalRemarks
                                    )(
                                        <TextArea
                                            disabled={disabled}
                                            placeholder="Enter Remarks"
                                            className="input"
                                        />
                                    )}
                                </Item>
                            </div>
                        </div>

                        <div className="section">
                            <p className="label">Attachments</p>
                            <div className="content">
                                <Item hasFeedback={true}>
                                    {getFieldDecorator(
                                        'attachment',
                                        attachment
                                    )(
                                        <FilePicker
                                            uploadProps={{
                                                accept: '*/*',
                                                multiple: true,
                                                disabled: disabled,
                                            }}
                                            disabled={disabled}
                                        />
                                    )}
                                </Item>
                            </div>
                        </div>
                        <div>
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <div className="section">
                                        <p className="label">Discount</p>
                                        <div className="content">
                                            <div className="inline">
                                                <Item hasFeedback={true} label={'Discount (' + symbol + ')'}>
                                                    {getFieldDecorator(
                                                        'discount',
                                                        discount
                                                    )(
                                                        <InputNumber
                                                            disabled={disabled}
                                                            className="input"
                                                            min={0}
                                                            max={total}
                                                            placeholder={'Enter Discount(' + symbol + ')'}
                                                        />
                                                    )}
                                                </Item>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <div className="estimation">
                                        <div className="heading">
                                            <p>Cost Estimation</p>
                                        </div>
                                        <div className="list">
                                            {array.map((item, index) => {
                                                const { label, value } = item
                                                return (
                                                    <div key={index} className="item">
                                                        <p className="label">{label}</p>
                                                        <p className="value">
                                                            {symbol} {value}
                                                        </p>
                                                    </div>
                                                )
                                            })}
                                            {discountValue ? (
                                                <div className="item">
                                                    <p className="label">Discount</p>
                                                    <p className="value">{symbol} {discountValue} </p>
                                                </div>
                                            ) : null}
                                            {array.length === 0 ? (
                                                <p className="empty">No Items Added</p>
                                            ) : null}
                                        </div>
                                        <div className="total">
                                            <p className="label">Total</p>
                                            <p className="value">
                                                {symbol} {(total - (discountValue || 0)).toFixed(2)}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </React.Fragment>
                )}
                {!disabled && (
                    <div className="actions">
                        <Button htmlType={'submit'} className="btn primary-btn save">
                            Save
                        </Button>
                        <Button
                            htmlType={'button'}
                            className="btn red-btn-text"
                            onClick={hideModal}
                        >
                            Cancel
                        </Button>
                    </div>
                )}
            </Form>
        </div>
    )
}
export default view
