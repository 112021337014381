import React from 'react'
import { Form, Button, Input } from 'antd'
import TelephoneInput from 'core-components/telephone-input'

const { Item } = Form
//import './styles.scss';
/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {

    const { hideModal, form, rules, placeholder, label, isPhone, translate } = this.props
    const { getFieldDecorator } = form
    return (
        <div className='test-form form'>
            <Form onSubmit={this.handleSubmit}>
                <Item hasFeedback={true} label={label}>
                    {
                        getFieldDecorator('value', { rules })(
                            isPhone ? <TelephoneInput /> :
                                <Input
                                    maxLength={200}
                                    className='input'
                                    placeholder={placeholder}
                                />
                        )
                    }
                </Item>
                <div className="actions">
                    <Button htmlType={'submit'} type="primary"
                        className="btn primary-btn">{translate('test.save')}</Button>
                    <Button htmlType={'button'} onClick={hideModal}
                        className="btn red-btn-text">{translate('test.cancel')}</Button>
                </div>
            </Form>
        </div>
    )
}
export default view
