import React from "react";
import "./styles.scss";
import { Col, Icon, Row } from "antd";
import { getAllISOCodes } from "iso-country-currency";
import { nFormatter } from "../../utils/common";
import Link from "../../components/link";
var view = function () {
  const { CURRENCY, enableClick } = this.props;
  const { symbol } = getAllISOCodes().find((item) => {
    return item.currency === CURRENCY;
  }) || { symbol: CURRENCY };
  const { data = {} } = this.state;
  const { unpaid = {month : {}, year : {}}, paid = {month : {}, year : {}}} = data

  const array = [
    {
      icon: "calendar",
      label: "Unpaid Invoices of this month",
      ...unpaid.month,
    },
    {
      icon: "calendar",
      label: "Paid Invoices of this month",
      ...paid.month,
    },
    {
      icon: "bar-chart",
      label: "Unpaid Invoices of this year",
      ...unpaid.year,
    },
    {
      icon: "bar-chart",
      label: "Paid Invoices of this year",
      ...paid.year,
    },
  ];
  return (
    <React.Fragment>
      {array.map((item, index) => {
        const { icon, label, count, amount } = item;
        return (
          <Col key={index} xs={24} sm={12} md={12} lg={12} xl={6}>
            <Link routeKey={enableClick ? "home.invoice" : undefined}>
              <div className="card">
                <div className="info">
                  <div className="icon">
                    <Icon type={icon} />
                  </div>
                  <div className="details">
                    <p className="count">{symbol + " " + nFormatter(amount || 0)}</p>
                    <p className="label">
                      {count || 0} {label}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </Col>
        );
      })}
    </React.Fragment>
  );
};
export default view;
