import React from "react";
import preProcess from "../../preprocess";
import { Form, Button, Input, message } from "antd";
import { createAction, ActionNames } from "app-redux/actions";
import FilePicker from "../../../components/file-picker";

const { create, Item } = Form;
const { TextArea } = Input;
const spinningSelector = ".form";

const Main = (props) => {
  const {
    form,
    data,
    translate,
    hideModal,
    updateRecord,
    createRecord,
    getTableData,
    uploadFile,
  } = props;

  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const validations = {
    name: {
      rules: [
        {
          required: true,
          message: "Name is Required",
        },
      ],
      initialValue: data && data.name,
    },
    description: {
      rules: [
        {
          required: true,
          message: "Description is Required",
        },
      ],
      initialValue: data && data.description,
    },
    designation: {
      rules: [
        {
          required: true,
          message: "Designation is Required",
        },
      ],
      initialValue: data && data.designation,
    },
    image: {
      rules: [
        {
          required: true,
          message: "Image is Required",
        },
      ],
      initialValue: data && data.image,
    },
  };

  async function uploadPicture(base64, name) {
    if (base64) {
      try {
        const { error, payload } = await uploadFile({
          file: base64.dataUri,
          type: "image",
          name,
        });
        if (error) {
          throw payload.response;
        }
        return payload.data.url;
      } catch (e) {
        throw {
          message: "Image did not Uploaded Successfully.",
        };
      }
    }
  }

  const onSubmit = (event) => {
    event.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      window.startSpinning(spinningSelector);
      try {
        const { image, name } = values;
        if(image instanceof File){
          const imageUrl = await uploadPicture(image, name);
          values.image = imageUrl;
        }
        

        if (data) {
          const { error, payload } = await updateRecord(data.key, values);
          if (error) {
            throw payload.response;
          }
        } else {
          const { error, payload } = await createRecord(values);
          if (error) {
            throw payload.response;
          }
        }
        if (data) {
          message.success("Testimonials updated successfully");
        } else {
          message.success("Testimonials created successfully");
        }
        if (hideModal instanceof Function) {
          hideModal();
        }
        if (getTableData instanceof Function) {
          getTableData();
        }
      } catch (e) {
        console.log(e);
        if (data) {
          message.error("failed to update Testimonials");
        } else {
          message.error("failed to create Testimonials");
        }
      }
      window.stopSpinning(spinningSelector);
    });
  };

  const { image, description, name, designation } = validations;

  return (
    <div className="form">
      <Form onSubmit={onSubmit}>
        <Item label="Name" hasFeedback>
          {getFieldDecorator(
            "name",
            name
          )(<Input className="input" placeholder={"Enter Name"} />)}
        </Item>
        <Item label="Description" hasFeedback>
          {getFieldDecorator(
            "description",
            description
          )(<TextArea rows={3} placeholder={"Enter Description"} />)}
        </Item>
        <Item label="Designation" hasFeedback>
          {getFieldDecorator(
            "designation",
            designation
          )(<Input className="input" placeholder={"Enter Designation"} />)}
        </Item>
        <Item hasFeedback={true} label={"Image"}>
          {getFieldDecorator(
            "image",
            image
          )(
            <FilePicker
              uploadProps={{
                accept: "*/*",
                multiple: false,
              }}
            ></FilePicker>
          )}
        </Item>
        <div className="actions">
          <Button type="primary" className="btn green-btn" htmlType="submit">
            {translate("parts.submit")}
          </Button>
          <Button className="btn red-btn-text" onClick={hideModal}>
            {translate("parts.cancel")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

const bindAction = (dispatch) => {
  return {
    createRecord: (data) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "testimonials",
          ...data,
        })
      );
    },
    updateRecord: (entityId, data) => {
      return dispatch(
        createAction(ActionNames.UPDATE_ENTITY, {
          entityId,
          entityName: "testimonials",
          ...data,
        })
      );
    },
    uploadFile: ({ file, type, name }) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "storage",
          file,
          type,
          name,
          fileCode: "configurationFile",
        })
      );
    },
  };
};
Main.displayName = "Part-Form";
export default preProcess(create()(Main), {
  localize: true,
  connect: [null, bindAction],
});
