import React, { useState } from 'react'
import preProcess from '../../preprocess'
import { Form, Button, Input, message, AutoComplete } from 'antd'
import { createAction, ActionNames } from 'app-redux/actions'
import './styles.scss'

const { create, Item } = Form
const { TextArea } = Input
const spinningSelector = '.form'
const Main = props => {
    const { form, data, translate,submitEnquiry} = props
    const { getFieldDecorator, validateFieldsAndScroll,resetFields } = form
    const validations = {
        name: {
            rules: [{
                required: true,
                message: translate('contact.name.required')
            }],
            initialValue: data && data.name
        },
        email: {
            rules: [{
                required: true,
                message: translate('contact.email.required')
            }],
            initialValue: data && data.email
        },
        phone: {
            initialValue: data && data.phone
        },
        message : {
            rules: [{
                required: true,
                message: "Please enter the message"
            }],
        }

    }
    const onSubmit = event => {
        event.preventDefault()
        validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return;
            }
            window.startSpinning(spinningSelector);
            try {
                const { error, payload } = await submitEnquiry({
                    ...values
                });
                if (error) {
                    throw payload.response;
                }
                resetFields();
                message.success(translate('support.success'));
            } catch (e) {
                message.error(translate('support.error'));
                console.error(e)
            }
            window.stopSpinning(spinningSelector);
        })
    }

    const { name, email, phone } = validations
    return (
        <div className='form contact-form'>
            <Form onSubmit={onSubmit}>
                <div className="inline">
                    <Item label={translate('contact.name.label')} hasFeedback>
                        {
                            getFieldDecorator('name', name)(
                                <Input className='input' placeholder={translate('contact.name.placeholder')} />
                            )
                        }
                    </Item>
                </div>
                <div className="inline">
                    <Item label={translate('contact.email.label')} hasFeedback>
                        {
                            getFieldDecorator('email', email)(
                                <Input className='input' placeholder={translate('contact.email.placeholder')} />
                            )
                        }
                    </Item>
                </div>
                <div className="inline">
                    <Item label={translate('contact.phone.label')} hasFeedback>
                        {
                            getFieldDecorator('phone', phone)(
                                <Input className='input' placeholder={translate('contact.phone.placeholder')} />
                            )
                        }
                    </Item>
                </div>
                <div className="inline">
                    <Item label={translate('contact.message.label')} hasFeedback>
                        {
                            getFieldDecorator('message', validations.message)(
                                <TextArea className='input' autoSize={{ minRows: 4, maxRows: 4 }} placeholder={translate('contact.message.placeholder')}></TextArea>
                            )
                        }
                    </Item>
                </div>

                <div className="actions">
                    <Button type="primary" className="btn primary-btn"
                        htmlType='submit'>{translate('parts.message')}</Button>
                </div>

            </Form>
        </div>
    )
}
const mapStateToProps = ({ }) => {
    return {}
}
const bindAction = dispatch => {
    return {
        submitEnquiry: data => {
            return dispatch(createAction(ActionNames.CREATE_ENTITY, {
                entityName: 'contact',
                ...data
            }));
        }
    }
}
Main.displayName = 'Part-Form'
export default preProcess(create()(Main), {
    localize: true,
    connect: [mapStateToProps, bindAction]
})