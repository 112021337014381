import React from "react";
import { formatDate } from "../utils/date-util";
import BackupCreate from "../containers/backup-create";
import BackupRestore from "../containers/backup-restore";
import { Button, Icon } from "antd";
import { ModalTrigger } from "core-components/modal";
import BackupSettings from "../containers/backupSettings";

export default {
  entity: "backup",
  entityName: "Back Up",
  selection: null,
  search: false,
  deleteAction: window.app.isDemoEnabled ? false : true,
  pagination: {
    type: "server",
  },
  headerActions: [
    (props) => {
      return (
        <ModalTrigger
          content={<BackupSettings {...props} />}
          modalProps={{
            title: "Backup Settings",
            className: "backup-form small-width",
            footer: null,
          }}
        >
          <Button className="primary-btn">
            <span className="icon">
              <Icon type="setting" />
            </span>
            <span className="text">Backup Settings</span>
          </Button>
        </ModalTrigger>
      );
    },
    BackupCreate,
  ],
  rowActions: [BackupRestore],
  defaultSort: {
    sort: "createdAt",
    sortType: "desc",
  },
  columns: ({ sharedState: { translate } }) => [
    {
      title: translate("backup.date"),
      dataIndex: "createdAt",
      render: (createdAt) => {
        return <p>{formatDate(createdAt)}</p>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Finished At",
      dataIndex: "updatedAt",
      render: (finishedAt, { status }) => {
        return status === "Finished" ? <p>{formatDate(new Date(finishedAt))}</p> : null;
      }
    },
  ],
};
